import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter, faWater} from '@fortawesome/free-solid-svg-icons';//https://fontawesome.com/icons

/*Components of Automated-Life*/
import { useAuthValue } from "../Shared/AuthProvider";
import Notifications from "../Notifications/Notifications";
import { UpdateSensor, GetSensor } from "automated-provider";
import Loading from "../Shared/Loading";

export default function SensorNivel(parameters) {
    const { currentUser } = useAuthValue();
    const [user, setUser] = useState(null);
    const [sensor, setSensor] = useState(null);
    const [mode, setMode] = useState("easy");
    const [loadSensor, setLoadSensor] = useState(true);

    useEffect(() => {
        setLoadSensor(true);
        setUser(parameters.user);
        setSensor(parameters.sensor);
        setLoadSensor(false);
    }, [])


    const changeMode = async () => {
        if (mode === "easy") {
            setMode("advance");
        } else {
            setMode("easy");
        }

    };

    const refresh = async (sid) => {
        if (sid !== undefined) {          
            GetSensor(currentUser.uid, sid).then((responseSensor) => {       
                setSensor(responseSensor);
            });
        }
    }

    const Notificacion = async (sid, type, status) => {
        //console.log("sid : " + sid + " - type : " + type + " - status :" + !status);
        if(type === "aviso_no"){
            sensor.aviso_no = true;
            sensor.aviso_email = false;
            sensor.aviso_sms = false;
            sensor.aviso_push = false;
        }else{
            sensor[type] = !status;
            sensor.aviso_no = false;
        }
        UpdateSensor(currentUser.uid, sid, sensor).then((response) => {
            refresh(sid);
        });
    };

    if(loadSensor === true){
        return (<><div className={mode === "easy" ? 'col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4' : 'col-12'} ><Loading /></div></>);
    }else{
        return (
            <>
                <div className={mode === "easy" ? 'col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4' : 'col-12'} >
                    <div className="panel-sensor">
                        <div className="row">
                            {mode === "easy" ? (
                                <>
                                    <div className="col-6 col-xs-6 col-sm-9 col-md-9 col-lg-9">
                                        <FontAwesomeIcon className={sensor.estado === 'on' ? "status-enabled" : "status-disabled"} icon={faWater} size="2x" />
                                        &nbsp;&nbsp;
                                        <span className="name">{sensor.nombre}</span>
                                    </div>
                                    <div className="col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 text-end">
                                        <FontAwesomeIcon id={"icon_" + sensor.key} icon={mode === "easy" ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter} size="1x" onClick={() => changeMode()} />
                                    </div>                                
                                </>
                            ) : (
                                <>
                                    <div className="col-6 col-xs-6 col-sm-9 col-md-9 col-lg-10">
                                            <FontAwesomeIcon className={sensor.estado === 'on'? "status-enabled" : "status-disabled"} icon={faWater} size="2x" />
                                            &nbsp;&nbsp;
                                        <span className="name">{sensor.nombre}</span>
                                    </div>
                                    <div className="col-6 col-xs-6 col-sm-3 col-md-3 col-lg-2 text-end">
                                        <FontAwesomeIcon id={"icon_" + sensor.key} icon={mode === "easy" ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter} size="1x" onClick={() => changeMode()} />
                                    </div>
                                </>
                            )
                            }
                            {mode === "easy" ? (
                                <div className="col-12 text-center">
                                    <span className="description-sensor">{sensor.estado === 'on' ? 'Encima del nivel' : 'Debajo del nivel'}</span>
                                </div>
                            ) : (
                                <>
                                    <div className="col-12 text-center">
                                            <span className="description-sensor">{sensor.estado === 'on' ? 'Encima del nivel' : 'Debajo del nivel'}</span>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 center-on-small-only">
                                                <p className="text-xs-center">Notificación de activación y apagado</p>
                                                <div className="form-check form-switch form-check-inline">
                                                    <input type="checkbox" id={sensor.key + "_email"} checked={sensor.aviso_email === true ? true : false} name="notifications" onChange={() => Notificacion(sensor.key, 'aviso_email', sensor.aviso_email)} className="form-check-input" />
                                                    <label className="form-check-label">Email</label>
                                                </div>
                                                <div className="form-check form-switch form-check-inline">
                                                    <input type="checkbox" id={sensor.key + "_sms"} checked={sensor.aviso_sms === true ? true : false} name="notifications" onChange={() => Notificacion(sensor.key, 'aviso_sms', sensor.aviso_sms)} className="form-check-input" />
                                                    <label className="form-check-label">SMS</label>
                                                </div>
                                                <div className="form-check form-switch form-check-inline">
                                                    <input type="checkbox" id={sensor.key + "_push"} checked={sensor.aviso_push === true ? true : false} name="notifications" onChange={() => Notificacion(sensor.key, 'aviso_push', sensor.aviso_push)} className="form-check-input" />
                                                    <label className="form-check-label">Push</label>
                                                </div>
                                                <div className="form-check form-switch form-check-inline">
                                                    <input type="checkbox" id={sensor.key + "_no"} checked={sensor.aviso_no === true ? true : false} name="notifications" onChange={() => Notificacion(sensor.key, 'aviso_no', sensor.aviso_no)} className="form-check-input" />
                                                    <label className="form-check-label">Desactivadas</label>
                                                </div>
                                            </div>                                    
                                        </div>
                                    </div>
                                    <div className="w-100"></div>
                                    <Notifications deviceSensor={sensor} lastSize="Top-5" />
                                </>
                            )
                            }
                            <div className="col-12">
                                <p className={sensor.ultima_conexion_status === true ? 'status-enabled text-end' : 'status-disabled text-end'} data-toggle="popover" data-placement="top" data-content={sensor.ultima_conexion}>
                                    {sensor.ultima_conexion_status === true ? 'Con conexión' : 'Sin conexión'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}