import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faChartArea, faComment, faCogs, faUserSecret, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

/*Componentes of Automated-Life*/
import { GetCurrentUserFireBase, LogOutUser, GetAuthenticatedUser } from "automated-provider";
import Login from "../Login/Login";

export default function Menu(parameter) {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const abortController = new AbortController();

    useEffect(() => {
        if (parameter.user != null) {
            GetCurrentUserFireBase(parameter.user).then((response) => {
                setUser(response);
            });
        }

        return () => {
            setUser({});
        };
    }, [])    

    const redirect = (url) => {
        GetAuthenticatedUser().then((response) => {
            if(response != null){
                navigate('/app/' + url);
                abortController.abort();
            }else{
                signOutFirebase();
            }
        });        
    };

    const signOutFirebase = () => {
        LogOutUser().then(() => {
            window.location.reload();
        });        
    };

    if (parameter != null && parameter.user != null && user != null) {
        return (
            <div className="text-center">
                <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 panel-menu">                    
                    <div className="panel-menu-opcion" onClick={() => redirect('perfil')}>
                        <FontAwesomeIcon icon={faUser} size="2x" className={window.location.pathname === "/app/perfil" ? "status-enabled" : ""} />
                        <p className="link"><Link to="perfil">{user.nombre}</Link></p>
                    </div>
                    <div className="panel-menu-opcion" onClick={() => redirect('estadisticas')}>
                        <FontAwesomeIcon icon={faChartArea} size="2x" className={window.location.pathname === "/app/estadisticas" ? "status-enabled" : ""} />
                        <p className="link"><Link to="estadisticas">Estadisticas</Link></p>
                    </div>
                    <div className="panel-menu-opcion" onClick={() => redirect('notificaciones')}>
                        <FontAwesomeIcon icon={faComment} size="2x" className={window.location.pathname === "/app/notificaciones" ? "status-enabled" : ""} />
                        <p className="link"><Link to="notificaciones">Notificaciones</Link></p>
                    </div>
                    <div className="panel-menu-opcion" onClick={() => redirect('dispositivos')}>
                        <FontAwesomeIcon icon={faCogs} size="2x" className={((window.location.pathname === "/app/dispositivos" || window.location.pathname === "/app/") ? "status-enabled" : "")} />
                        <p className="link"><Link to="dispositivos">Dispositivos y Sensores</Link></p>
                    </div>
                    { user.roles.administrador ? (
                    <div className="panel-menu-opcion" onClick={() => redirect('admin')}>
                        <FontAwesomeIcon icon={faUserSecret} size="2x" className={window.location.pathname === "/app/admin" ? "status-enabled" : ""} />
                        <p className="link"><Link to="admin">Administración</Link></p>
                    </div>
                    ) : null}
                    <div className="panel-menu-opcion" onClick={() => signOutFirebase()} >
                        <FontAwesomeIcon icon={faSignOutAlt} size="2x" className={window.location.pathname === "/app/login" ? "status-enabled" : ""} />
                        <p className="link"><Link to="login?signOut">Salir</Link></p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Login />)
    }
}