import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter, faTachographDigital } from '@fortawesome/free-solid-svg-icons';//https://fontawesome.com/icons

/*Components of Automated-Life*/
import { useAuthValue } from "../Shared/AuthProvider";
import { GetSensor, ChangeSensor, GetDevice, ChangeTemperature, UpdateDevice, GetCurrentUserFireBase } from "automated-provider";
import RoundSlider from "./RoundSlider"
import Loading from "../Shared/Loading";
import Notifications from "../Notifications/Notifications"
import AutomaticProgramming from "./AutomaticProgramming"

var lastTimeChangeTemp = 0;
var newTempSlider = 0;
var delayChangeTemp = 1500;

export default function DeviceTemp(parameters) {
    const { currentUser } = useAuthValue();
    const [user, setUser] = useState(null);
    const [mode, setMode] = useState("easy");
    const [device, setDevice] = useState(null);
    const [sensor, setSensor] = useState(null);
    const [roundSliderKey, setRoundSliderKey] = useState(0);
    const [notificationKey, setnotificationKey] = useState(0);
    const [loadDevice, setLoadDevice] = useState(true);

    useEffect(() => {
        setLoadDevice(true);
        setUser(parameters.user);
        setDevice(parameters.device);
        if (parameters.device.entrada_salida.entrada !== "") {
            GetSensor(currentUser.uid, parameters.device.entrada_salida.entrada).then((responseSensor) => {
                setSensor(responseSensor);
                DetectChangeSensor(responseSensor);
                setLoadDevice(false);
            });            
        }
    }, [])

    const DetectChangeSensor = async (sensor) =>{
        ChangeSensor(currentUser.uid, parameters.device.entrada_salida.entrada).then((responseSensor) => {
            if(responseSensor.property === "valor"){
                let sensorUpdate = Object.assign({}, sensor);
                sensorUpdate.valor = responseSensor.value;
                setSensor(sensorUpdate);
                DetectChangeSensor(sensorUpdate);
            }            
        }); 
    }

    const refresh = async (pid) => {
        if (pid !== undefined) {          
            GetDevice(currentUser.uid, pid).then((responseDevice) => {       
                setDevice(responseDevice);
            });
        }
    }

    const changeMode = async () => {
        if (mode === "easy") {
            setMode("advance");
        } else {
            setMode("easy");
        }
        
    };

    const changeTemp = async (e) => {
        $(e.control[0]).roundSlider("disable");

        newTempSlider = e.value;

        if (lastTimeChangeTemp >= (Date.now() - delayChangeTemp))
            return;

        lastTimeChangeTemp = Date.now();

        setTimeout(function () {
            GetCurrentUserFireBase(currentUser).then((responseUser) => {
                ChangeTemperature(responseUser, device, sensor, newTempSlider, true).then((response) => {
                    refresh(device.key).then((response) => {
                        setRoundSliderKey(roundSliderKey => roundSliderKey + 1);
                        $(e.control[0]).roundSlider("enable");
                    });
                })
            });            
        }, delayChangeTemp)        
    }

    const Notificacion = async (pid, type, status) => {
        //console.log("pid : " + pid + " - type : " + type + " - status :" + !status);
        if(type === "aviso_no"){
            device.aviso_no = true;
            device.aviso_email = false;
            device.aviso_sms = false;
            device.aviso_push = false;
        }else{
            device[type] = !status;
            device.aviso_no = false;
        }
        UpdateDevice(currentUser.uid, pid, device).then((response) => {
            refresh(pid);
        });
    };
    const ActivacionAutomatica = async (pid, property, enableDisabled) => {
        setnotificationKey(notificationKey => notificationKey + 1);
        device[property] = !enableDisabled;
        UpdateDevice(currentUser.uid, pid, device).then((response) => {
            refresh(pid);
        });
    };

    if(loadDevice === true){
        return (<><div className={mode === "easy" ? 'col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4' : 'col-12'} ><Loading /></div></>);
    }else{
        return (
        <>
            <div className={mode === "easy" ? 'col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4' : 'col-12'} >
                <div className="panel-device">
                    <div className="row">
                        <div className="col-6 col-xs-6 col-sm-9 col-md-9 col-lg-9">
                                <FontAwesomeIcon className={device.estado === 'on' ? "status-enabled" : "status-disabled"} icon={faTachographDigital} size="2x" />
                                &nbsp;&nbsp;
                            <span className="name">{device.nombre}</span>                        
                        </div>
                        <div className="col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 text-end">
                            <FontAwesomeIcon id={"icon_" + device.key} icon={mode === "easy" ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter} size="1x" onClick={() => changeMode()} />
                        </div>
                        {mode === "easy" ? (
                            <div className="col-12 text-center">
                                <span className="description-device">{device.entrada_salida.valor} &deg;C</span>
                                <div className="clearfix"></div>
                                <span className="tempSet">{sensor.valor} &deg;C</span>
                            </div>
                        ) : (
                            <>
                                <div className="col-12 col-xl-5 text-center">
                                    <RoundSlider key={roundSliderKey} id={device.key} value={device.entrada_salida.valor} update={changeTemp} pid={device.key} setTemp={sensor.valor} deviceStatus={device.estado} />
                                </div>
                                <div className="col-12 col-xl-7">
                                    <div className="row">
                                        <div className="col-12 center-on-small-only">
                                            <p className="text-xs-center">Notificación de activación y apagado</p>
                                            <div className="form-check form-switch form-check-inline">
                                                <input type="checkbox" id={device.key + "_email"} checked={device.aviso_email === true ? true : false} name="notifications" onChange={() => Notificacion(device.key, 'aviso_email', device.aviso_email)} className="form-check-input" />
                                                <label className="form-check-label">Email</label>
                                            </div>
                                            <div className="form-check form-switch form-check-inline">
                                                <input type="checkbox" id={device.key + "_sms"} checked={device.aviso_sms === true ? true : false} name="notifications" onChange={() => Notificacion(device.key, 'aviso_sms', device.aviso_sms)} className="form-check-input" />
                                                <label className="form-check-label">SMS</label>
                                            </div>
                                            <div className="form-check form-switch form-check-inline">
                                                <input type="checkbox" id={device.key + "_push"} checked={device.aviso_push === true ? true : false} name="notifications" onChange={() => Notificacion(device.key, 'aviso_push', device.aviso_push)} className="form-check-input" />
                                                <label className="form-check-label">Push</label>
                                            </div>
                                            <div className="form-check form-switch form-check-inline">
                                                <input type="checkbox" id={device.key + "_no"} checked={device.aviso_no === true ? true : false} name="notifications" onChange={() => Notificacion(device.key, 'aviso_no', device.aviso_no)} className="form-check-input" />
                                                <label className="form-check-label">Desactivadas</label>
                                            </div>                                        
                                        </div>
                                        {parameters.device.manual === false ? (
                                            <>
                                                <div className="w-100"></div>
                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-xs-center">
                                                    <p className="text-xs-center">Activación automática</p>
                                                    <div className="form-check form-switch ">
                                                        <input type="checkbox" id={device.key + "_activacion_automatica"} checked={device.activacion_automatica === true ? true : false} onChange={() => ActivacionAutomatica(device.key, 'activacion_automatica', device.activacion_automatica)} className="form-check-input" />
                                                        <label className="form-check-label"></label>
                                                    </div>
                                                </div> 
                                            </>
                                        ) : (<>  </>)}                                                                           
                                    </div>
                                </div>
                                {device.activacion_automatica === true ? (
                                    <>
                                        <div className="col-12">
                                            <p className="text-xs-center">Activaciones automáticas</p>                                        
                                        </div>
                                        <AutomaticProgramming key={'automaticProgramming_' + device.key} id={device.key} type="cliente-pins" />
                                    </>
                                ) : (<>  </>)}
                                <Notifications key={notificationKey} deviceSensor={device} lastSize="Top-5" />
                            </>
                            )
                        }
                        <div className="col-12">
                            <p className={device.ultima_conexion_status === true ? 'status-enabled text-end' : 'status-disabled text-end'} data-toggle="popover" data-placement="top" data-content={device.ultima_conexion}>
                                {device.ultima_conexion_status === true ? 'Con conexión' : 'Sin conexión'}
                            </p>
                        </div>
                        {mode === "advance" ? (
                        <>                        
                        
                        </>
                        ) : ( <>  </>)}
                    </div>
                </div>
            </div>
        </>
        )
    }
}