import React, { useState, useEffect } from "react";
import { render } from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Moment from 'react-moment';
import 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilterCircleXmark, faFilter } from '@fortawesome/free-solid-svg-icons';//https://fontawesome.com/icons

/*Components of Automated-Life*/
import Header from "../Shared/Header";
import Loading from "../Shared/Loading";
import { useAuthValue } from "../Shared/AuthProvider";
import { getDatabase, GetStatistics, GetNotificationsById, SetLocalStorageObject, GetLocalStorageObject, GetConfig } from "automated-provider";

/*Componentes of FireBase*/
import { ref, onChildAdded } from "firebase/database";

export default function Statistics(parameters) {
    const moment = require('moment');
    const { currentUser } = useAuthValue();
    const [devicesSensores, setDevicesSensores] = useState([]);
    const [title, setTitle] = useState("");
    const [filter, setFilter] = useState({key : null, mode: "Day", start : null, end : null});
    const [loadStatistics, setloadStatistics] = useState(true);
    const [loadChart, setLoadChart] = useState(true);
    let config = useState({diffMinSetTempStatistic : null});

    const [rangeStart, setRangeStart] = useState("");
    const [rangeEnd, setRangeEnd] = useState("");

    const [options, setOptions] = useState({
      chart: {
        type: 'area',
        borderRadius: 10,
        //backgroundColor: '#003051',
        //borderWidth : 0,
        //height: (9 / 16 * 100) + '%',
        //width: 500,
        events: {
              beforePrint: function () {
                  this.oldhasUserSize = this.hasUserSize;
                  this.resetParams = [this.chartWidth, this.chartHeight, false];
                  this.setSize(600, 400, false);
              },
              afterPrint: function () {
                  this.setSize.apply(this, this.resetParams);
                  this.hasUserSize = this.oldhasUserSize;
              }
          }
        },
        //legend: {
        //  labelFormat: '<span style="color:#003150fc">{name}</span>'
        //},
        credits : false,
        title: null,
        subtitle:null,
        plotOptions: {
          area: {
              fillOpacity: 0.5,
          }
        },
        yAxis: {
          labels: {
              enabled: false
          },
          title: {
              text: ''
          },
          gridLineWidth: 0,
          minorGridLineWidth: 0,
        },
        xAxis : {
          labels: {
              formatter: function () {
                  return moment(this.value).format('DD') + '/' + moment(this.value).format('MM') + '/' + moment(this.value).format('YYYY') + ' - ' + moment(this.value).format('HH:mm');
              }
          }
        },
        tooltip : {
          xDateFormat: '%d-%m-%Y',
          shared: true,
          useHTML: true,
          formatter: function (tooltip) {
              GetLocalStorageObject("filter").then((filter) => {
                var responseText;
                switch(filter.mode)
                {
                  case "Day":
                    responseText = "Hora " + this.x;
                    break;
                  case "Week":
                    responseText = "Día " + this.x;
                    break;
                  case "Month":
                    
                    break;
                  case "Year":
                    break;
                  default:
                    break;
                }
                for (var index = 0; index < this.points.length; index += 1) {
                  if (this.points[index].series.userOptions.id.split("_")[1].indexOf("temp") > -1) {
                    responseText += "<br/>"+this.points[index].series.userOptions.name+" : " + this.points[index].y + "º";
                  }
                  if (this.points[index].series.userOptions.id.split("_")[1].indexOf("status") > -1) {
                    responseText += "<br/>"+this.points[index].series.userOptions.name+" : "+ (this.points[index].y === 10 ? "Activado/a" : "Desactivado/a");
                  }
                }  
                tooltip.label.attr({
                  text: responseText
                })
              });              
          }
        },
        series : []
    });

    useEffect(() => {
      if (currentUser != null) {
        moment.suppressDeprecationWarnings = true;
        moment.locale('es-ES', {
            week : {
                dow : 1 // Monday is the first day of the week
            },
            monthsShort : ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec']
        });
        
        var promises = [];
        promises.push(new Promise(async (resolve, reject) => {
          try {
            setDevicesSensores([]);
            onChildAdded(ref(getDatabase(), 'cliente-pins/' + currentUser.uid + '/'), (data) => {
                let dataTemp = data.val();
                dataTemp.key = data.key;
                dataTemp.type = "device";
                GetNotificationsById(currentUser.uid, data.key).then((responseNotifications) => {
                    if (dataTemp.visible)
                      setDevicesSensores(devicesSensores => [...devicesSensores, dataTemp]);
                });
            });
            onChildAdded(ref(getDatabase(), 'cliente-sensores/' + currentUser.uid + '/'), (data) => {
                let dataTemp = data.val();
                dataTemp.key = data.key;
                dataTemp.type = "sensor";
                GetNotificationsById(currentUser.uid, data.key).then((responseNotifications) => {
                    if (dataTemp.visible)
                      setDevicesSensores(devicesSensores => [...devicesSensores, dataTemp]);
                });
            });
            resolve({ "queryGetDevicesSensoresAndStatistics" : true});
          } catch (e) {
            reject({ "queryGetDevicesSensoresAndStatistics": false });
          }
        }));
        promises.push(new Promise(async (resolve, reject) => {
          try {
            GetStatistics(currentUser.uid).then((responseStatistics) => {
              SetLocalStorageObject("statistics", responseStatistics).then((response) => {
                resolve({ "queryGetDevicesSensoresAndStatistics" : true});
              })
            });
          } catch (e) {
            reject({ "queryGetDevicesSensoresAndStatistics": false });
          }
        }));

        setloadStatistics(true);
        Promise.all(promises).then((response) => {
          var statisticsIsValid = true;
          response.forEach(function (Statistic, index_statistics) {
            if(Statistic.queryGetDevicesSensoresAndStatistics == false){
              statisticsIsValid = false;
            }
          });
          if(statisticsIsValid){
            GetConfig().then((response_config) => {
              config = {diffMinSetTempStatistic : response_config.diffMinSetTempStatistic};
            }).then(() => {
              GetLocalStorageObject("filter").then((filter) => { 
                if(Object.keys(filter).length == 0){
                  resetFilter();
                }else{
                  setModefilter(filter, true);
                }
                setloadStatistics(false);
              });
            });            
          }
        })
      }
    }, [])

    const resetFilter = async () => { 
      GetLocalStorageObject("filter").then((filter) => {    
        var newFilter = {...filter};
        newFilter.key = null;
        setModefilter(newFilter, true);
      });
    }

    const setModefilter = async(mode, reset) => {
      GetLocalStorageObject("filter").then((filter) => { 
        setLoadChart(true);  
        var newFilter;
        if(!reset){
          newFilter = {...filter};
          newFilter.mode = mode;
        }else{
          newFilter = mode;
        }        
        setFilter(newFilter);
        SetLocalStorageObject("filter", newFilter).then((filter) => {
          processStatistics(newFilter).then((process) => {
            setLoadChart(false);
          });
        });
      });      
    }

    const processStatistics = async (filter) => {
      console.log("processStatistics with diff : " + config.diffMinSetTempStatistic);
      var data_pins = [];
      GetLocalStorageObject("statistics").then((statistics) => { 
        var opt = {...options};  
        opt.series = [];
        setOptions(opt);
          
        var categories = [];
        var modeRange;
        switch(filter.mode)
        {
          case "Day":
            filter.start = 0;
            filter.end = moment().hour();
            for (let hour = filter.start; hour <= filter.end; hour++) {
              categories.push((hour < 10 ? '0' + hour : hour) + ':00');
              for (let min = 1; min <= 59; min++) {
                console.log("% min : " + min + " : " + (min % config.diffMinSetTempStatistic));
                if(min % config.diffMinSetTempStatistic == 0)
                  categories.push((hour < 10 ? '0' + hour : hour) + ":" + (min < 10 ? '0' + min : min));
              }
              //categories.push((hour < 10 ? '0' + hour : hour) + ':00');
              //categories.push((hour < 10 ? '0' + hour : hour) + ':30');
            }
            console.log("categorias : " + JSON.stringify(categories));
            break;
          case "Week":
            filter.start = moment().add(-7, 'days');
            filter.end = moment();
            console.log("start : " + filter.start.format('YYYY-MM-DD'));
            console.log("end : " + filter.end.format('YYYY-MM-DD'));
            var dateEach = filter.start;          
            for (let day = filter.start.date(); day <= filter.end.date(); day++) {
              categories.push(moment(dateEach).format('DD') + '/' + moment(dateEach).format('MMM'));
              dateEach = moment(dateEach).add(1, 'days');
            }
            break;
          case "Month":
            filter.start = moment().add(-30, 'days');
            filter.end = moment().add(1, 'days');
            console.log("start : " + filter.start.format('YYYY-MM-DD'));
            console.log("end : " + filter.end.format('YYYY-MM-DD'));
            var dateEach = filter.start;
            for (let day = 1; day <= filter.end.diff(filter.start, 'days'); day++) {
              categories.push(moment(dateEach).format('DD') + '/' + moment(dateEach).format('MMM'));
              dateEach = moment(dateEach).add(1, 'days');
            }
            break;
          case "Year":
            filter.start = moment().add(-(365 - moment().daysInMonth()), 'days');
            filter.end = moment().add(1, 'months');
            var dateEach = filter.start;
            for (let day = 1; day <= filter.end.diff(filter.start, 'months'); day++) {
              categories.push(moment(dateEach).format('MMM') + '-' + moment(dateEach).format('YY'));
              dateEach = moment(dateEach).add(1, 'months');
            }
            break;
          case "Range":       
            if(filter.start == null || filter.start == undefined || filter.end == null || filter.end == undefined)
              return;

            var dateEach = filter.start;            

            console.log("days diff :" + moment(filter.end).diff(moment(filter.start), 'days'));

            if(moment(filter.end).diff(moment(filter.start), 'days') <= 1){
              for (let hour = 0; hour <= moment().hour(); hour++) {
                categories.push((hour < 10 ? '0' + hour : hour) + ':00');
                categories.push((hour < 10 ? '0' + hour : hour) + ':30');
              }
              modeRange = "Day";
            }else if(moment(filter.end).diff(moment(filter.start), 'days') <= 30){
              for (let day = 1; day <= moment(filter.end).add(1, 'days').diff(moment(filter.start), 'days'); day++) {
                categories.push(moment(dateEach).format('DD') + '/' + moment(dateEach).format('MMM'));
                dateEach = moment(dateEach).add(1, 'days');
              }
              modeRange = "Week";
            }else if(moment(filter.end).diff(moment(filter.start), 'days') > 30){
              for (let day = 1; day <= moment(filter.end).add(1, 'months').diff(moment(filter.start), 'months'); day++) {
                categories.push(moment(dateEach).format('MMM') + '-' + moment(dateEach).format('YY'));
                dateEach = moment(dateEach).add(1, 'months');
              }
              modeRange = "Year";
            }
            break;
        }

        opt.xAxis  = { categories: categories  };
        
        if(statistics != null && statistics != undefined && statistics.length > 0)
        {          
            //console.log("statistics : " + JSON.stringify(statistics));
            statistics.forEach(function (data_pin, index_pin) {
            switch(data_pin.type)
            {
              case "temperatura_in":
              case "temperatura":
                switch(filter.mode)
                {            
                  case "Day": 
                  case "Week":
                  case "Month":   
                  case "Year":
                  case "Range":              
                    categories.forEach(function (value, index_valor){
                      data_pin.dataChart.push({xAxisTemp : [value, 0], xAxisStatus : [value, 0], type : data_pin.type });
                    });     
                    data_pins.push(data_pin);             
                    break;
                  default:
                    break;
                }
                break;
              case "nivel":
              case "relay":
              case "humo":
              case "presion":
                switch(filter.mode)
                {            
                  case "Day": 
                  case "Week":
                  case "Month":   
                  case "Year":
                  case "Range":              
                    categories.forEach(function (value, index_valor){
                      data_pin.dataChart.push({xAxisStatus : [value, 0], type : data_pin.type });
                    });     
                    data_pins.push(data_pin);             
                    break;
                  default:
                    break;
                }
                break;
              default:
                break;
            }
          });

          if(filter.name !== null && filter.name !== undefined)
          {
            setTitle("Estadísticas del dispositivo " + filter.name);
          }else{
            setTitle("Estadísticas de los dispositivos y sensores");
          }

          if(data_pins != null && data_pins != undefined && data_pins.length > 0)
          {
            var temperature = 0;
            var size = 0;
            var lastValue = "";
            var lastState = "";
            data_pins.forEach(function (data_pin, index_pin) {       
              //console.log("Pin : " + data_pin.type);
              //console.log("filter key : " + filter.key);
              var process_data_pin = true;

              if(filter.key != null && filter.key != undefined){
                if(data_pin.key !== filter.key)
                  process_data_pin = false;
              }

              if(process_data_pin){
                data_pin.data.forEach( function (value, index_valor) {
                  switch(data_pin.type)
                  {
                    case "temperatura_in":
                    case "temperatura":
                      var valid = false;                     
                      switch(modeRange != null ? modeRange : filter.mode)
                      {
                        case "Day":   
                          /*console.log("modeRange : " + (modeRange != null ? modeRange : filter.mode));
                          console.log("Fecha : " + value.fecha);
                          console.log("hora : " + moment(value.fecha).format('HH'));
                          console.log("filterEnd : " +filter.end);
                          console.log("day : " + moment(value.fecha).format('DD'));
                          console.log("day compare : " + moment().date());*/
                          if(moment(value.fecha).format('DD') == moment().date() == true
                            && moment(value.fecha).format('HH') <= filter.end){
                            valid = true;
                          }
                          break;
                        case "Week":
                        case "Month": 
                        case "Year":
                          //console.log("Week start :" + filter.start.format('YYYY-MM-DD'));
                          //console.log("Week fecha : " + moment(Date.UTC(moment(value.fecha).subtract(1, 'months').format('YYYY'), moment(value.fecha).subtract(1, 'months').format('MM'), moment(value.fecha).subtract(1, 'months').format('DD'))).format('YYYY-MM-DD'));
                          //console.log("Week end :" + filter.end.format('YYYY-MM-DD'));

                          
                          if((moment(moment(Date.UTC(moment(value.fecha).subtract(1, 'months').format('YYYY'), moment(value.fecha).subtract(1, 'months').format('MM'), moment(value.fecha).subtract(1, 'months').format('DD')))).isAfter(filter.start) == true 
                          && moment(moment(Date.UTC(moment(value.fecha).subtract(1, 'months').format('YYYY'), moment(value.fecha).subtract(1, 'months').format('MM'), moment(value.fecha).subtract(1, 'months').format('DD')))).isBefore(filter.end) == true)
                          || moment(value.fecha).format('DD') == moment().date())
                          {
                            valid = true;
                          }
                          break;
                      }
                      
                      if (valid) {
                        //console.log("isvalid : " + valid);
                        switch(modeRange != null ? modeRange : filter.mode)
                        {
                          case "Day":
                            switch(data_pin.type)
                            {
                              case "temperatura_in":
                              case "temperatura":
                                //console.log("value : " + (data_pin.type === "temperatura" ? value.valor : value.estado));
                                data_pin.dataChart.filter(k => k.xAxisTemp).map(k => k.xAxisTemp).find(x => x[0] === moment(value.fecha).format('HH') + ':' + moment(value.fecha).format('mm'))[1] = toPrecision(parseFloat(value.valor), 2);
                                data_pin.dataChart.filter(k => k.xAxisStatus).map(k => k.xAxisStatus).find(x => x[0] === moment(value.fecha).format('HH') + ':' + moment(value.fecha).format('mm'))[1] = (value.estado === "on" ? 10 : 0);
                                break;
                              case "nivel":
                              case "relay":
                              case "humo":
                              case "presion":
                                data_pin.dataChart.filter(k => k.xAxisStatus).map(k => k.xAxisStatus).find(x => x[0] === moment(value.fecha).format('HH') + ':' + moment(value.fecha).format('mm'))[1] = (value.estado === "on" ? 10 : 0);
                                break;
                              default:
                                break;
                            }
                            break;
                          case "Week":
                          case "Month":
                            switch(data_pin.type)
                            {
                              case "temperatura_in":
                              case "temperatura":
                                if(lastValue !== "" && lastValue !== moment(value.fecha).subtract(1, 'months').format('YYYY-MM-DD')){
                                  data_pin.dataChart.filter(k => k.xAxisTemp).map(k => k.xAxisTemp).find(x => x[0] === moment(value.fecha).format('DD') + '/' + moment(value.fecha).format('MMM'))[1] = toPrecision(parseFloat(temperature / size), 2);
                                  data_pin.dataChart.filter(k => k.xAxisStatus).map(k => k.xAxisStatus).find(x => x[0] === moment(value.fecha).format('DD') + '/' + moment(value.fecha).format('MMM'))[1] = (lastState === "on" ? 10 : 0);
                                  
                                  temperature = 0;
                                  size = 0;
                                }
                                break;
                              case "nivel":
                              case "relay":
                              case "humo":
                              case "presion":
                                data_pin.dataChart.filter(k => k.xAxisStatus).map(k => k.xAxisStatus).find(x => x[0] === moment(value.fecha).format('DD') + '/' + moment(value.fecha).format('MMM'))[1] = (lastState === "on" ? 10 : 0);
                                break;
                              default:
                                break;
                            }
                              
                            lastValue = moment(value.fecha).subtract(1, 'months').format('YYYY-MM-DD');
                            if(value.estado === "" || value.estado === "on"){
                              lastState = value.estado;
                            }
                            break;
                          case "Year":
                            switch(data_pin.type)
                            {
                              case "temperatura_in":
                              case "temperatura":
                                console.log("Current month : " + moment(value.fecha).subtract(1, 'months').format('MMM') + "-" + moment(value.fecha).subtract(1, 'months').format('YY'));
                                if(lastValue !== "" && lastValue !== moment(value.fecha).subtract(1, 'months').format('MMM')){
                                  data_pin.dataChart.filter(k => k.xAxisTemp).map(k => k.xAxisTemp).find(x => x[0] === moment(value.fecha).format('MMM')+ "-" + moment(value.fecha).subtract(1, 'months').format('YY'))[1] = toPrecision(parseFloat(temperature / size), 2);
                                  data_pin.dataChart.filter(k => k.xAxisStatus).map(k => k.xAxisStatus).find(x => x[0] === moment(value.fecha).format('MMM')+ "-" + moment(value.fecha).subtract(1, 'months').format('YY'))[1] = (lastState === "on" ? 10 : 0);
                                  
                                  temperature = 0;
                                  size = 0;
                                }
                                break;
                              case "nivel":
                              case "relay":
                              case "humo":
                              case "presion":
                                data_pin.dataChart.filter(k => k.xAxisStatus).map(k => k.xAxisStatus).find(x => x[0] === moment(value.fecha).format('MMM')+ "-" + moment(value.fecha).subtract(1, 'months').format('YY'))[1] = (lastState === "on" ? 10 : 0);
                                break;
                              default:
                                break;
                            }

                            lastValue = moment(value.fecha).subtract(1, 'months').format('MMM')+ "-" + moment(value.fecha).subtract(1, 'months').format('YY');
                            if(value.estado === "" || value.estado === "on"){
                              lastState = value.estado;
                            }
                            break;
                        }
                        temperature += parseFloat(value.valor);
                        size += 1;
                      }                      
                      break;
                    case "relay":
                      break;
                    default:
                      break;
                  }               
                });
                setSerieChart(opt, data_pin, filter, modeRange);  
              }                
            });
            setOptions(opt);
          }else{
            console.log("pins is null");
          }  
        }else{
          console.log("statistics is null init");
        }    
      });
    }

    const setSerieChart = async (opt, serie, filter, modeRange) => {     
      switch(modeRange != null ? modeRange : filter.mode)
      {
        case "Day":         
          switch(serie.type){
            case "temperatura_in":
            case "temperatura":
              if(serie.data.length > 0 && serie.dataChart.length > 0){
                //console.log("serie: " + serie.name);
                //console.log("data Temp: " + JSON.stringify(serie.dataChart.filter(k => k.type === serie.type).map(d => d.xAxisTemp)));
                //console.log("data status : " + JSON.stringify(serie.dataChart.filter(k => k.type === serie.type).map(d => d.xAxisStatus)));
                opt.series.push({
                  name: "Temperatura de " + serie.name,
                  data: serie.dataChart.filter(k => k.type === serie.type).map(d => d.xAxisTemp),
                  color: serie.color,
                  borderWidth: 0,
                  dataLabels: {
                      enabled: true,
                      color: serie.color,
                      formatter: function () {
                        return this.y + ' Cº'
                      }
                  },
                  cursor: 'pointer',
                  point: {
                    events: {
                      click: function () {
                          return;
                      }
                    }
                  },
                  id: serie.key+"_temp"
                });     
                opt.series.push({
                  name: serie.name,
                  data: serie.dataChart.filter(k => k.type === serie.type).map(d => d.xAxisStatus),
                  color: '#ff9330',
                  borderWidth: 0,
                  dataLabels: {
                      enabled: true,
                      color: '#ff9330',
                      formatter: function () {
                        return this.y === 10 ? 'Activado' : 'Desactivado'
                      }
                  },
                  cursor: 'pointer',
                  point: {
                    events: {
                      click: function () {
                          return;
                      }
                    }
                  },
                  id: serie.key+"_status"
                });            
              }       
              break;
            case "nivel":
            case "relay":
            case "humo":
            case "presion":
              opt.series.push({
                name: serie.name,
                data: serie.dataChart.filter(k => k.type === serie.type).map(d => d.xAxisStatus),
                color: serie.color,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    color: serie.color,
                    formatter: function () {
                      return this.y === 10 ? 'Activado' : 'Desactivado'
                    }
                },
                cursor: 'pointer',
                point: {
                  events: {
                    click: function () {
                        return;
                    }
                  }
                },
                id: serie.key+"_status"
              }); 
              break;
            default:
              break;
          }                 
          break; 
        case "Week":
        case "Month":
        case "Year":
          switch(serie.type){
            case "temperatura_in":
            case "temperatura":
              console.log("serie.data : " + serie.data.length);
              console.log("serie.dataChart : " + serie.dataChart.length);
              if(serie.data.length > 0){
                opt.series.push({
                  name: "Temperatura de " + serie.name,
                  data: serie.dataChart.filter(k => k.type === serie.type).map(d => d.xAxisTemp),
                  color: serie.color,
                  borderWidth: 0,
                  dataLabels: {
                      enabled: true,
                      color: serie.color,
                      formatter: function () {
                        return this.y + ' Cº'
                      }
                  },
                  cursor: 'pointer',
                  point: {
                    events: {
                      click: function () {
                          return;
                      }
                    }
                  },
                  id: serie.key+"_temp"
                });      
                opt.series.push({
                  pin: serie.name,
                  name: serie.name,
                  data: serie.dataChart.filter(k => k.type === serie.type).map(d => d.xAxisStatus),
                  color: '#ff9330',
                  borderWidth: 0,
                  dataLabels: {
                      enabled: true,
                      color: '#ff9330',
                      formatter: function () {
                        return this.y === 10 ? 'Activado' : 'Desactivado'
                      }
                  },
                  cursor: 'pointer',
                  point: {
                    events: {
                      click: function () {
                          return;
                      }
                    }
                  },
                  id: serie.key+"_status"
                });           
              }       
              break;
            case "nivel":
            case "relay":
            case "humo":
            case "presion":
              opt.series.push({
                name: serie.name,
                data: serie.dataChart.filter(k => k.type === serie.type).map(d => d.xAxisStatus),
                color: serie.color,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    color: serie.color,
                    formatter: function () {
                      return this.y === 10 ? 'Activado' : 'Desactivado'
                    }
                },
                cursor: 'pointer',
                point: {
                  events: {
                    click: function () {
                        return;
                    }
                  }
                },
                id: serie.key+"_status"
              }); 
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }   
    }

    const filterDeviceSensor = (dsid, name) => {
      GetLocalStorageObject("filter").then((filter) => {
        var newFilter = {...filter};
        newFilter.key = dsid;
        newFilter.name = name;
        setModefilter(newFilter, true);
      });
    };

    const filterRangeDates = (dayStart, dayEnd) => {
      
      GetLocalStorageObject("filter").then((filter) => {
        var newFilter = {...filter};
        if(rangeStart !== null && rangeStart != ""){
          console.log("start : " + rangeStart);
          newFilter.start = rangeStart;
        }

        if(rangeEnd !== null && rangeEnd != ""){
          console.log("end : " + rangeEnd);
          newFilter.end = rangeEnd;
        }

        if(newFilter.start != null && newFilter.end != null)
          newFilter.mode = "Range";
          SetLocalStorageObject("filter", newFilter)
          console.log("start : " + newFilter.start + " - end : " + newFilter.end);
          setModefilter(newFilter, true);
      });   
    };

    const toPrecision = (number, precision) => {
      var prec = Math.pow(10, precision);
      return Math.round(number * prec) / prec;
    }

    if (loadStatistics) {
      return (<div className="container"><Header user={currentUser} /><Loading /></div>);
    } else {
      return (
          <div className="container">
            <Header user={ currentUser } />
            <div className="w-100"></div>
            <div className="row">
              <div className="col-12">
                {!loadChart ? <p className="name page-title text-sm-start text-center">{title}</p> : <p className="name page-title text-sm-start text-center">Estadísticas de los dispositivos y sensores</p> }
              </div>                
            </div>
            <div className="w-100"></div>
            <div className="row">
              {
                devicesSensores.map((deviceSensor) => {
                  return (
                      <div key={deviceSensor.key + "_" + deviceSensor.fecha} className="col-4 col-sm-3">
                          <div className={deviceSensor.type === "device" ? "statistics-sphere notification-device" : "statistics-sphere notification-sensor"}>
                              
                              <div className="statistics-filter">
                                {
                                  (filter.key!== undefined && filter.key !== null && ((filter.key.indexOf('-') > 0 && deviceSensor.key === filter.key.split("-")[1]) || (deviceSensor.key === filter.key) || (filter.name === deviceSensor.name))) ? (
                                  <FontAwesomeIcon className="status-active" icon={faFilterCircleXmark} size="1x" onClick={() => resetFilter()}/>
                                  ):(
                                  <FontAwesomeIcon className="status-inactive" icon={faFilter} size="1x" onClick={() => filterDeviceSensor(deviceSensor.key, deviceSensor.nombre)}/>
                                  )
                                }
                              </div>
                              <div className="notification-name">{deviceSensor.nombre}</div>
                          </div>
                      </div>
                  )
                })                 
              }
            </div> 
            <div className="w-100"></div>
            <div className="row">
                <div className="col-12">
                  <ul className="nav nav-tabs nav-fill custom-nav-statistics">
                    <li className="nav-item" onClick={() => setModefilter("Day", false)}>
                      <a className={filter.mode === "Day" ? "nav-link active" : "nav-link"} href="#">Hoy</a>
                    </li>
                    <li className="nav-item" onClick={() => setModefilter("Week", false)}>
                      <a className={filter.mode === "Week" ? "nav-link active" : "nav-link"} href="#">Última semana</a>
                    </li>
                    <li className="nav-item" onClick={() => setModefilter("Month", false)}>
                      <a className={filter.mode === "Month" ? "nav-link active" : "nav-link"} href="#">Último mes</a>
                    </li>
                    <li className="nav-item" onClick={() => setModefilter("Year", false)}>
                      <a className={filter.mode === "Year" ? "nav-link active" : "nav-link"} href="#">Último año</a>
                    </li>
                    <li className="nav-item" onClick={() => setModefilter("Range", false)}>
                      <a className={filter.mode === "Range" ? "nav-link active" : "nav-link"} href="#">Por fechas</a>
                    </li>
                  </ul>
                </div>                  
                { filter.mode === "Range" ? 
                  <>
                    <div className="w-100"></div>                      
                    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-3 form-group">
                          <label className="control-label input">Fecha inicio</label>
                          <input type="date" className="form-control form-date" placeholder="aaaa-mm-dd" defaultValue={filter.start} onChange={(e) => { setRangeStart(e.currentTarget.value); }} />
                    </div>
                    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-3 form-group">
                      <label className="control-label input">Fecha fin</label>
                      <input type="date" className="form-control form-date" placeholder="aaaa-mm-dd" defaultValue={filter.end} onChange={(e) => { setRangeEnd(e.currentTarget.value) }} />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-6 form-group-button">
                      <button type="submit" className="btn btn-save" onClick={(e) => { filterRangeDates() }}>Buscar</button>
                    </div>
                  </> : <></>
                }
                {loadChart ? (<Loading />) : ( <><div className="w-100"></div><HighchartsReact highcharts={Highcharts} options={options} /></> )}
            </div>
            <div className="w-100"></div>
        </div>
      )
    }
}