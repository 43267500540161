import React from "react";

export default function NotFound(parameters) {
		
	return (<>
			<div className="row">
				<div className="col-middle">

				</div>
			</div>
			<div className="col-12">
				<div className="panel">
					<div className="text-center">
						<h2 className="error-number">Upps!!!</h2>
						<h1>Error - 404 <small> La página no existe ó algo ha salido mal.</small></h1>
						<br />
					</div>
				</div>
			</div>
		</>
	)
}