import React, { useState, useEffect } from "react";
import { v4 as uuid } from 'uuid';

/*Components of Automated-Life*/
import Header from "../Shared/Header";
import { useAuthValue } from "../Shared/AuthProvider";
import { getDatabase, GetUserFireBase, WebPushNotification, SaveUser, SaveUserAuthorize, SaveDevice, SaveSensor, DeleteUserAuthorize } from "automated-provider";
import Loading from "../Shared/Loading";

/*Componentes of FireBase*/
import { ref, onChildAdded } from "firebase/database";

export default function Profile(parameters) {
    const { currentUser } = useAuthValue();
    const [user, setUser] = useState("user_empty");
    const [collapse, setCollapse] = useState('user');

    const [devices, setDevices] = useState(null);
    const [sensores, setSensores] = useState(null);
    const [autorizados, setAutorizados] = useState(null);
    const [loadController, setLoadController] = useState(true);

    useEffect(() => {
        if (currentUser != null) {
            setLoadController(true);
            let dataUser;
            GetUserFireBase(currentUser.uid).then((responseUser) => {
                dataUser = responseUser;
                dataUser.key = currentUser.uid;
                //Se cargarn primero sensores antes que dispositivos por la dependencia en algunos dispositivos de entrada asociado a un sensor
                setSensores([]);
                onChildAdded(ref(getDatabase(), 'cliente-sensores/' + currentUser.uid + '/'), (data) => {
                    let dataSen = data.val();
                    dataSen.key = data.key;
                    setSensores(sensores => [...sensores, dataSen]);
                });
                setDevices([]);
                onChildAdded(ref(getDatabase(), 'cliente-pins/' + currentUser.uid + '/'), (data) => {
                    let dataPin = data.val();
                    dataPin.key = data.key;
                    setDevices(devices => [...devices, dataPin]);
                });
                setAutorizados([]);
                onChildAdded(ref(getDatabase(), 'clientes/' + currentUser.uid + '/autorizados'), (data) => {
                    let dataAuth = data.val();
                    dataAuth.key = data.key;
                    setAutorizados(autorizados => [...autorizados, dataAuth]);
                });

            }).then(() => {
                setCollapse('user');
                setUser(dataUser);
                setLoadController(false);
            });            
        }
    }, [])

    const userHandleChange = async (e) => { 
        if (currentUser != null) {
            var userPendingUpdate = user;
            
            var propertyUpdateJoin = e.target.name.split('.');
            propertyUpdateJoin.shift();

            if (e.target.selectedIndex === undefined) {
                var value = e.target.value.trim();
                if (e.target.name === "estado") {
                    value = e.target.checked === true ? "activado" : "desactivado"
                }
                userPendingUpdate[propertyUpdateJoin] = value;
            } else {
                var propertyParentNode = propertyUpdateJoin[0];
                var propertySubNode = propertyUpdateJoin[1];

                var indexOption = e.target.selectedIndex;
                var item = e.target.childNodes[indexOption];
                var keyValue;
                var updateProperty = false;
                if (item.getAttribute('data-key') !== null) {
                    keyValue = item.getAttribute('data-key').split('.').slice(-1).pop();
                    if (propertySubNode !== keyValue.trim() && propertyParentNode !== keyValue.trim()) {
                        updateProperty = true;
                    } else {
                        updateProperty = false;
                    }
                } else {
                    updateProperty = false;
                }

                if (updateProperty) {
                    keyValue = item.getAttribute('data-key').split('.').slice(-1).pop();
                    if (propertySubNode !== undefined) {
                        userPendingUpdate[propertyParentNode][propertySubNode] = keyValue.trim();
                    } else {
                        userPendingUpdate[propertyParentNode] = keyValue.trim();
                    }
                } else {
                    if (propertySubNode !== undefined) {
                        delete userPendingUpdate[propertyParentNode][propertySubNode];
                    } else {
                        delete userPendingUpdate[propertyParentNode];
                    }
                }
            }
            
            setDevices(devices);
            setUser(user);
        }    
    };
    
    const changeCollapse = async (e) => {
        if (currentUser != null) {
            setCollapse(e.target.getAttribute("data-collapsetype"));      
        }
    }

    const saveUsuario = async (e) => {
        if (currentUser != null) {
            e.preventDefault();
            SaveUser(user.key, user).then((responseUser) => {
                WebPushNotification("El usuario se ha actualizado correctamente", "Actualización de usuario", "success");
            });
        }
    };

    const saveDispositivo = async (e, key) => {
        if (currentUser != null) {
            e.preventDefault();

            var device = devices[devices.findIndex(k => k.key === key)];

            if (device['nombre'] === "") {
                WebPushNotification("Es necesario indicar el nombre del dispositivo", "Actualización de dispositivo", "warning");
                return;
            }
            if (device['entrada_salida'].tipo === undefined) {
                WebPushNotification("Es necesario indicar el tipo de dispositivo", "Actualización de dispositivo", "warning");
                return;
            }

            SaveDevice(user.key, key, device).then((response) => {
                setCollapse('device');
                WebPushNotification("El dispositivo se ha actualizado correctamente", "Actualización de dispositivo", "success");
            });
        }
    };

    const saveSensor = async (e, key) => {
        if (currentUser != null) {
            e.preventDefault();

            var sensor = sensores[sensores.findIndex(k => k.key === key)];
            
            if (sensor['nombre'] === "") {                    
                WebPushNotification("Es necesario indicar el nombre del sensor", "Actualización de sensor", "warning");
                return;
            }
            if (sensor['tipo_sensor'] === undefined) {
                WebPushNotification("Es necesario indicar el tipo de sensor", "Actualización de sensor", "warning");
                return;
            }

            SaveSensor(user.key, key, sensor).then((response) => {
                setCollapse('sensor');
                WebPushNotification("El sensor se ha actualizado correctamente", "Actualización de sensor", "success");
            });
        }       
    };

    const deviceHandleChange = async (key, e) => {
        if (currentUser != null) {
            const index = devices.findIndex(k => k.key === key);
            var devicePendingUpdate = [...devices]; // important to create a copy, otherwise you'll modify state outside of setState call
            var propertyUpdateJoin = e.target.name.split('.');
            propertyUpdateJoin.shift();

            if (devicePendingUpdate[index] !== undefined) {
                handleChangeProperty(e, devicePendingUpdate[index], propertyUpdateJoin);
                setDevices(devices);
            }  
        }     
    };

    const sensorHandleChange = (key, e) => {
        if (currentUser != null) {
            const index = sensores.findIndex(k => k.key === key);
            var sensorPendingUpdate = [...sensores]; // important to create a copy, otherwise you'll modify state outside of setState call
            var propertyUpdateJoin = e.target.name.split('.');
            propertyUpdateJoin.shift();

            if (sensorPendingUpdate[index] !== undefined) {
                handleChangeProperty(e, sensorPendingUpdate[index], propertyUpdateJoin);
            }
            setSensores(sensores);
        }               
    };

    const handleChangeProperty = async (e, node, property) => {
        if (e.target.selectedIndex === undefined) {
            node[property] = e.target.value.trim();
        } else {
            var propertyParentNode = property[0];
            var propertySubNode = property[1];

            var indexOption = e.target.selectedIndex;
            var item = e.target.childNodes[indexOption];
            var keyValue;
            var updateProperty = false;
            if (item.getAttribute('data-key') !== null) {
                keyValue = item.getAttribute('data-key').split('.').slice(-1).pop();
                if (propertySubNode !== keyValue.trim() && propertyParentNode !== keyValue.trim()) {
                    updateProperty = true;
                } else {
                    updateProperty = false;
                }
            } else {
                updateProperty = false;
            }

            if (updateProperty) {
                keyValue = item.getAttribute('data-key').split('.').slice(-1).pop();
                if (propertySubNode !== undefined) {
                    node[propertyParentNode][propertySubNode] = keyValue.trim();
                } else {
                    node[propertyParentNode] = keyValue.trim();
                }
            } else {
                if (propertySubNode !== undefined) {
                    delete node[propertyParentNode][propertySubNode];
                } else {
                    delete node[propertyParentNode];
                }
            }
        }
    }

    const deleteUsuarioAutorizado = async (e, key) => {
        if (currentUser != null) {
            e.preventDefault();

            DeleteUserAuthorize(user.key, key).then((response) => {
                const index = autorizados.findIndex(k => k.key === key);

                autorizados.splice(index, 1);
                setAutorizados([]);
                setAutorizados(autorizados);

                setCollapse('autorizado');
                WebPushNotification("El usuario autorizado se ha eliminado correctamente", "Eliminación de autorizado", "success");
            }); 
        }
    };

    const desactivarActivarUsuarioAutorizado = async (e, key, value) => {
        if (currentUser != null) {
            e.preventDefault();

            const index = autorizados.findIndex(k => k.key === key);
            var authorizePendingUpdate = [...autorizados];
            authorizePendingUpdate[index]['estado'] = value ? 'activado' : 'desactivado';
            setAutorizados([]);
            setAutorizados(autorizados);

            setCollapse('autorizado');

            SaveUserAuthorize(user.key, key, authorizePendingUpdate[index]).then((responseUser) => {
                WebPushNotification("El usuario autorizado se ha actualizado correctamente", "Actualización de autorizados", "success");
            });
        }
    };

    const saveUsuarioAutorizado = async (e, key) => {
        if (currentUser != null) {
            e.preventDefault();                

            var autorizado = autorizados[autorizados.findIndex(k => k.key === key)];

            if (autorizado['nombre'] === "") {
                WebPushNotification("Es necesario indicar el nombre del autorizado", "Actualización de autorizado", "warning");
                return;
            }
            if (autorizado['usuario_telegram'] === "") {
                WebPushNotification("Es necesario indicar el identificador de usuario en Telegram", "Actualización de autorizado", "warning");
                return;
            }

            const index = autorizados.findIndex(k => k.key === key);
            autorizados.splice(index, 1);
            setAutorizados(autorizados);

            SaveUserAuthorize(user.key, key, autorizado).then((responseUser) => {
                setCollapse('autorizado');
                WebPushNotification("El usuario autorizado se ha actualizado correctamente", "Actualización de autorizados", "success");
            });
        }
    };

    const autorizadoHandleChange = async (key, e) => {
        if (currentUser != null) {
            const index = autorizados.findIndex(k => k.key === key);
            var autorizadoPendingUpdate = [...autorizados]; // important to create a copy, otherwise you'll modify state outside of setState call
            var propertyUpdateJoin = e.target.name.split('.');
            propertyUpdateJoin.shift();

            if (autorizadoPendingUpdate !== undefined) {
                handleChangeProperty(e, autorizadoPendingUpdate[index], propertyUpdateJoin);
                setAutorizados(autorizados);
            }                
        }
    }

    const addUsuarioAutorizado = async () => {
        if (currentUser != null) {
            var autorizadoNew = {};
            autorizadoNew.key = uuid();
            autorizadoNew.nombre = '';
            autorizadoNew.estado = 'activado';
            autorizadoNew.usuario_telegram = '';
            
            setAutorizados(autorizados => [...autorizados, autorizadoNew]);
        }
    };

    if (loadController) {
        return (<><Header user={currentUser} /><Loading /></>);
    } else {
        return (
            <div className="container">
                <Header user={currentUser} />
                <div className="w-100"></div>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <p className="name page-title text-sm-start text-center">Perfil de usuario</p>
                </div>
                <div className="w-100"></div>
                <div className="col-12" data-status="open" data-disp="true">
                    <div id="tabType" className="panel">
                        <div className="row">
                            <div className="col-xs-12 col-md-4 col-sm-4 col-lg-3">
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="collapse" href={"#tab_datosusuario_" + user.key} role="button" data-collapsetype="user" aria-expanded={collapse === 'user' ? 'true' : 'false'} onClick={(e) => changeCollapse(e)} aria-controls="tab_datosusuario" data-toggle="tab">Datos de usuario y conexión</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="collapse" href={"#tab_dispositivos_" + user.key} role="button" data-collapsetype="device"  aria-expanded={collapse === 'device' ? 'true' : 'false'} onClick={(e) => changeCollapse(e)} aria-controls="tab_dispositivos" data-toggle="tab">Dispositivos</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="collapse" href={"#tab_sensores_" + user.key} role="button" data-collapsetype="sensor"  aria-expanded={collapse === 'sensor' ? 'true' : 'false'} onClick={(e) => changeCollapse(e)} aria-controls="tab_sensores" data-toggle="tab">Sensores</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="collapse" href={"#tab_autorizados_" + user.key} role="button" data-collapsetype="autorizado"  aria-expanded={collapse === 'autorizado' ? 'true' : 'false'} onClick={(e) => changeCollapse(e)} aria-controls="tab_autorizados" data-toggle="tab">Autorizados</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="clearfix d-flex d-sm-none">&nbsp;</div>
                            <div className="col-xs-12 col-md-8 col-sm-8 col-lg-9">
                                <div className={collapse === 'user' ? 'row collapse show' : 'row collapse'} data-bs-parent="#tabType" key={"tab_datosusuario_" + user.key} id={"tab_datosusuario_" + user.key}>
                                    <div key={user.key + '_' + uuid()} className="row">
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                            <label className="control-label">Nombre de usuario</label>
                                            <input type="text"
                                                className="form-control"
                                                id={user.key + ".nombre"}
                                                placeholder="Nombre"
                                                name={user.key + ".nombre"}
                                                defaultValue={user.nombre} onChange={(e) => { userHandleChange(e) }} />
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                            <label className="control-label">Email</label>
                                            <input type="text"
                                                className="form-control"
                                                id={user.key + ".email"}
                                                placeholder="Email"
                                                name={user.key + ".email"}
                                                disabled
                                                defaultValue={user.email}/>
                                        </div>
                                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 form-group has-feedback">
                                            <label className="control-label">Estado</label>
                                            <div className="form-check form-switch form-check-inline">
                                                <input type="checkbox" id={user.key + ".estado"} name="estado" disabled  checked={user.estado === "activado" ? true : false} className="form-check-input" />
                                                <label className="form-check-label">{user.estado === "activado" ? "Activado" : "Desactivado"}</label>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 form-group has-feedback">
                                            <label className="control-label">Teléfono</label>
                                            <input type="text"
                                                className="form-control"
                                                id={user.key + ".telefono"}
                                                placeholder="Teléfono"
                                                name={user.key + ".telefono"}
                                                defaultValue={user.telefono} onChange={(e) => { userHandleChange(e) }} />
                                        </div>
                                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 form-group has-feedback">
                                            <label className="control-label">Fecha de alta</label>
                                            <input type="text"
                                                className="form-control"
                                                id={user.key + ".fecha_alta"}
                                                placeholder="Fecha de alta"
                                                name={user.key + ".fecha_alta"}
                                                disabled
                                                defaultValue={user.fecha_alta} />
                                        </div>
                                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 form-group has-feedback">
                                            <label className="control-label">Última conexión</label>
                                            <input type="text"
                                                className="form-control"
                                                id={user.key + ".fecha_ultima"}
                                                placeholder="Última conexión"
                                                name={user.key + ".fecha_ultima"}
                                                disabled
                                                defaultValue={user.fecha_ultima} />
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                            <label className="control-label">Usuario de Telegram</label>
                                            <input type="text"
                                                className="form-control"
                                                id={user.key + ".usuario_telegram"}
                                                placeholder="Usuario de Telegram"
                                                name={user.key + ".usuario_telegram"}
                                                defaultValue={user.usuario_telegram} onChange={(e) => { userHandleChange(e) }} />
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                            <label className="control-label">Clave WIFI</label>
                                            <input type="text"
                                                className="form-control"
                                                id={user.key + ".clave_wifi"}
                                                placeholder="Clave WIFI"
                                                name={user.key + ".clave_wifi"}
                                                defaultValue={user.clave_wifi} onChange={(e) => { userHandleChange(e) }} />
                                        </div>
                                        <div className="col-xs-12">
                                            <ul className="nav">
                                                <li className="nav-item col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <a className="nav-link" data-bs-toggle="collapse" href="#SIM" role="button" aria-expanded="true" aria-controls="tabConnectionType" data-toggle="tab">Conexión con SIM</a>
                                                </li>
                                                <li className="nav-item col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <a className="nav-link" data-bs-toggle="collapse" href="#HTTP" role="button" aria-expanded="false" aria-controls="tabConnectionType" data-toggle="tab">Conexión con HTTP</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div id="tabConnectionType" className="col-xs-12">
                                            <div className="row collapse show" data-bs-parent="#tabConnectionType" id="SIM">
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                    <label className="control-label">Número de teléfono</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        id={user.key + ".sim"}
                                                        placeholder="+34666666666"
                                                        name={user.key + ".sim"}
                                                        defaultValue={user.sim} onChange={(e) => { userHandleChange(e) }} />
                                                </div>
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                    <label className="control-label">PIN</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        id={user.key + ".pin_sim"}
                                                        placeholder="PIN"
                                                        name={user.key + ".pin_sim"}
                                                        defaultValue={user.pin_sim} onChange={(e) => { userHandleChange(e) }}/>
                                                </div>
                                            </div>
                                            <div className="row collapse" data-bs-parent="#tabConnectionType" id="HTTP">
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                    <label className="control-label">IP</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        id={user.key + ".ip"}
                                                        placeholder="IP"
                                                        name={user.key + ".ip"}
                                                        defaultValue={user.ip} onChange={(e) => { userHandleChange(e) }} />
                                                </div>
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                    <label className="control-label">Puerto</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        id={user.key + ".puerto_ip"}
                                                        placeholder="Puerto"
                                                        name={user.key + ".puerto_ip"}
                                                        defaultValue={user.puerto_ip} onChange={(e) => { userHandleChange(e) }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-xs-12 text-right">
                                            <button type="submit" className="btn btn-save" onClick={(e) => { saveUsuario(e) }}>Guardar</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={collapse === 'device' ? 'row collapse show' : 'row collapse'} data-bs-parent="#tabType" key={"tab_dispositivos_" + user.key} id={"tab_dispositivos_" + user.key}>
                                    {
                                        (devices !== null && devices.length > 0) ?
                                            devices.map((device) => {
                                                return (
                                                    <div key={device.key + '_' + uuid()} className="row">
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                            <label className="control-label">Nombre del dispositivo</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={device.key + '_nombre'}
                                                                placeholder="Nombre"
                                                                name={device.key + '.nombre'}
                                                                disabled
                                                                defaultValue={device.nombre} />
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                            <label className="control-label">Descripción</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={device.key + '.descripcion'}
                                                                placeholder="Descripción"
                                                                name={device.key + '.descripcion'}
                                                                disabled
                                                                defaultValue={device.descripcion} />
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                            <label className="control-label">Tipo de dispositivo</label>
                                                            <select id={device.key + ".entrada_salida.tipo"} key={device.key + ".entrada_salida.tipo"} disabled defaultValue={device.entrada_salida.tipo} name={device.key + ".entrada_salida.tipo"} className="form-control" >
                                                                <option value="" key={device.key + ".entrada_salida"} data-key={device.key + ".entrada_salida"}>Seleccionar un tipo</option>
                                                                <option value="relay" key={device.key + ".entrada_salida.tipo.relay"} data-key={device.key + ".entrada_salida.tipo.relay"} >Relé</option>
                                                                <option value="temperatura" key={device.key + ".entrada_salida.tipo.temperatura"} data-key={device.key + ".entrada_salida.tipo.temperatura"}>Temperatura</option>
                                                                <option value="humos" key={device.key + ".entrada_salida.tipo.humos"} data-key={device.key + ".entrada_salida.tipo.humos"}>Humos</option>
                                                                <option value="alarma" key={device.key + ".entrada_salida.tipo.alarma"} data-key={device.key + ".entrada_salida.tipo.alarma"}>Alarma</option>
                                                                <option value="camara" key={device.key + ".entrada_salida.tipo.camara"} data-key={device.key + ".entrada_salida.tipo.camara"}>Cámara</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 form-group has-feedback">
                                                            <label className="control-label">Puerto</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={device.key + '.puerto'}
                                                                placeholder="Puerto"
                                                                name={device.key + '.puerto'}
                                                                disabled
                                                                defaultValue={device.puerto} />
                                                        </div>
                                                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 form-group has-feedback">
                                                            <label className="control-label">Ip LAN</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={device.key + '.ipLAN'}
                                                                placeholder="Ip LAN"
                                                                name={device.key + '.ipLAN'}
                                                                disabled
                                                                defaultValue={device.ipLAN} />
                                                        </div>
                                                        {
                                                            device.entrada_salida.tipo === "temperatura" ?
                                                                (
                                                                    <>
                                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                                            <label className="control-label">Sensor de entrada (Tº)</label>
                                                                            <select id={device.key + ".entrada_salida.entrada"} defaultValue={device.entrada_salida.entrada} disabled key={device.key + ".entrada_salida.entrada"} data-key={device.key + ".entrada_salida.entrada"} name={device.key + ".entrada_salida.entrada"} className="form-control" >
                                                                                <option defaultValue="">Seleccionar un sensor</option>
                                                                                {
                                                                                    sensores.map((sensor_entrada_salida) => {
                                                                                        return (<option value={sensor_entrada_salida.key} disabled key={sensor_entrada_salida.key} data-key={sensor_entrada_salida.key}>{sensor_entrada_salida.nombre}</option>)
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                                            <label className="control-label">Dispositivo de salida (Tº)</label>
                                                                            <select id={device.key + ".entrada_salida.salida"} disabled defaultValue={device.entrada_salida.salida} key={device.key + ".entrada_salida.salida"} data-key={device.key + ".entrada_salida.salida"} name={device.key + ".entrada_salida.salida"} className="form-control" >
                                                                                <option defaultValue="">Seleccionar un dispositivo</option>
                                                                                {
                                                                                    devices.map((device_entrada_salida) => {
                                                                                        return (<option value={device_entrada_salida.key} key={device_entrada_salida.key} data-key={device_entrada_salida.key}>{device_entrada_salida.nombre}</option>)
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </>
                                                                ) : null
                                                        }
                                                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 form-group has-feedback">
                                                            <label className="control-label">Manual ó automático</label>
                                                            <select id={device.key + ".manual"} disabled defaultValue={device.manual} key={device.key + ".manual"} data-key={device.key + ".manual"} name={device.key + ".manual"} className="form-control" >
                                                                <option value="" key={device.key + '.manual.empty'} data-key={device.key + '.manualempty'}>Seleccionar un modo</option>
                                                                <option value="true" key={device.key + '.manual.true'} data-key={device.key + '.manual.true'}>Manual</option>
                                                                <option value="false" key={device.key + '.manual.false'} data-key={device.key + '.manual.false'}>Automático</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 form-group has-feedback">
                                                            <label className="control-label">Orden gráfica</label>
                                                            <input type="number"
                                                                className="form-control"
                                                                id={device.key + ".orden_grafica"}
                                                                placeholder="Orden gráfica"
                                                                name={device.key + ".orden_grafica"}                                                                
                                                                defaultValue={device.orden_grafica} onChange={(e) => deviceHandleChange(device.key, e)}/>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 form-group has-feedback">
                                                            <label className="control-label">Color gráfica</label>
                                                            <input type="color"
                                                                className="form-control"
                                                                id={device.key + ".color_grafica"}
                                                                placeholder="Color gráfica"
                                                                name={device.key + ".color_grafica"}                                                                
                                                                defaultValue={device.color_grafica} onChange={(e) => deviceHandleChange(device.key, e)}/>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 form-group has-feedback">
                                                            <label className="control-label">Acciones</label>
                                                            <div className="btn-group actions" role="group">                                                               
                                                                <button className={device.visible === true ? "btn btn-save" : "btn btn-disable"} disabled={device.visible === true ? null : 'disabled'} tooltip="Guardar dispositivo" onClick={(e) => saveDispositivo(e, device.key)} type="button">Guardar</button>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="divider"></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                </div>
                                <div className={collapse === 'sensor' ? 'row collapse show' : 'row collapse'} data-bs-parent="#tabType" key={"tab_sensores_" + user.key} id={"tab_sensores_" + user.key}>
                                    {
                                        (sensores !== null && sensores.length > 0) ?
                                            sensores.map((sensor) => {
                                                return (
                                                    <div key={sensor.key + '_' + uuid()} className="row">
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                            <label className="control-label">Nombre del sensor</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={sensor.key + '.nombre'}
                                                                placeholder="Nombre"
                                                                name={sensor.key + '.nombre'}
                                                                disabled
                                                                defaultValue={sensor.nombre} />
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                            <label className="control-label">Descripción</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={sensor.key + '.descripcion'}
                                                                placeholder="Descripción"
                                                                name={sensor.key + '.descripcion'}
                                                                disabled
                                                                defaultValue={sensor.descripcion} />
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                                            <label className="control-label">Tipo de sensor</label>
                                                            <select id={sensor.key + '.tipo_sensor'} defaultValue={sensor.tipo_sensor} name={sensor.key + '.tipo_sensor'} disabled key={sensor.key + '.tipo_sensor'} data-key={sensor.key + '.tipo_sensor'} className="form-control" >
                                                                <option value="" key={sensor.key + '.tipo_sensor.empty'} data-key={sensor.key + '.tipo_sensor.empty'}>Seleccionar un tipo</option>
                                                                <option value="temperatura_in" key={sensor.key + '.tipo_sensor.temperatura_in'} data-key={sensor.key + '.tipo_sensor.temperatura_in'}>Entrada datos</option>
                                                                <option value="temperatura_out" key={sensor.key + '.tipo_sensor.temperatura_out'} data-key={sensor.key + '.tipo_sensor.temperatura_out'}>Salida datos</option>
                                                                <option value="humo" key={sensor.key + '.tipo_sensor.humo'} data-key={sensor.key + '.tipo_sensor.humo'}>Humos</option>
                                                                <option value="presion" key={sensor.key + '.tipo_sensor.presion'} data-key={sensor.key + '.tipo_sensor.presion'}>Presión</option>
                                                                <option value="nivel" key={sensor.key + '.tipo_sensor.nivel'} data-key={sensor.key + '.tipo_sensor.nivel'}>Nivel</option>
                                                                <option value="relay" key={sensor.key + '.tipo_sensor.relay'} data-key={sensor.key + '.tipo_sensor.relay'}>Relé</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 form-group has-feedback">
                                                            <label className="control-label">Puerto</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={sensor.key + '.puerto'}
                                                                placeholder="Puerto"
                                                                name={sensor.key + '.puerto'}
                                                                disabled
                                                                defaultValue={sensor.puerto} />
                                                        </div>
                                                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 form-group has-feedback">
                                                            <label className="control-label">Ip LAN</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={sensor.key + '.ipLAN'}
                                                                placeholder="Ip LAN"
                                                                name={sensor.key + '.ipLAN'}
                                                                disabled
                                                                defaultValue={sensor.ipLAN} />
                                                        </div>
                                                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 form-group has-feedback">
                                                            <label className="control-label">Orden gráfica</label>
                                                            <input type="number"
                                                                className="form-control"
                                                                id={sensor.key + '.orden_grafica'}
                                                                placeholder="Orden gráfica"
                                                                name={sensor.key + '.orden_grafica'}                                                                
                                                                defaultValue={sensor.orden_grafica} onChange={(e) => sensorHandleChange(sensor.key, e)}/>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 form-group has-feedback">
                                                            <label className="control-label">Color gráfica</label>
                                                            <input type="color"
                                                                className="form-control"
                                                                id={sensor.key + '.color_grafica'}
                                                                placeholder="Color gráfica"
                                                                name={sensor.key + '.color_grafica'}                                                                
                                                                defaultValue={sensor.color_grafica} onChange={(e) => sensorHandleChange(sensor.key, e)}/>
                                                        </div>  
                                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group has-feedback">
                                                            <label className="control-label">Acciones</label>
                                                            <div className="btn-group actions" role="group">                                                               
                                                                <button className={sensor.visible === true ? "btn btn-save" : "btn btn-disable"} disabled={sensor.visible === true ? null : 'disabled'} tooltip="Guardar sensor" onClick={(e) => saveSensor(e, sensor.key)} type="button">Guardar</button>
                                                            </div>
                                                        </div>                                                      
                                                        <div className="col-12">
                                                            <div className="divider"></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                </div>
                                <div className={collapse === 'autorizado' ? 'row collapse show' : 'row collapse'} data-bs-parent="#tabType" key={"tab_autorizados_" + user.key} id={"tab_autorizados_" + user.key}>
                                    {
                                        (autorizados !== null && autorizados.length > 0) ?
                                            autorizados.map((autorizado) => {
                                                return (
                                                    <div key={autorizado.key + '_' + uuid()} className="row">
                                                        <div className="col-xs-8 col-sm-4 col-md-4 col-lg-4 form-group has-feedback">
                                                            <label className="control-label">Nombre del autorizado</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={autorizado.key + '.nombre'}
                                                                placeholder="Nombre"
                                                                name={autorizado.key + '.nombre'}
                                                                defaultValue={autorizado.nombre} onChange={(e) => autorizadoHandleChange(autorizado.key, e)}/>
                                                        </div>
                                                        <div className="col-xs-8 col-sm-3 col-md-3 col-lg-3 form-group has-feedback">
                                                            <label className="control-label">ID de Usuario en Telegram</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={autorizado.key + '.usuario_telegram'}
                                                                placeholder="ID de Usuario en Telegram"
                                                                name={autorizado.key + '.usuario_telegram'}
                                                                defaultValue={autorizado.usuario_telegram} onChange={(e) => autorizadoHandleChange(autorizado.key, e)} />
                                                        </div>
                                                        <div className="col-xs-4 col-sm-5 col-md-5 col-lg-5 form-group has-feedback">
                                                            <label className="control-label">Acciones</label>
                                                            <div className="btn-group actions" role="group">
                                                                <button type="button" className={autorizado.estado === "activado" ? "btn btn-default" : "btn btn-disable"} disabled={autorizado.estado === "activado" ? null : 'disabled'} onClick={(e) => desactivarActivarUsuarioAutorizado(e, autorizado.key, false)}>Desactivar</button>
                                                                <button type="button" className={autorizado.estado === "activado" ? "btn btn-disable" : "btn btn-success"} disabled={autorizado.estado === "desactivado" ? null : 'disabled'} onClick={(e) => desactivarActivarUsuarioAutorizado(e, autorizado.key, true)}>Activar</button>
                                                                <button type="button" className="btn btn-danger" onClick={(e) => deleteUsuarioAutorizado(e, autorizado.key)}>Eliminar</button>
                                                                <button type="button" className="btn btn-save" tooltip="Guardar datos del usuario telegram" onClick={(e) => saveUsuarioAutorizado(e, autorizado.key)}>Guardar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <button className="btn btn-new" tooltip="Añadir usuario telegram" onClick={() => addUsuarioAutorizado()}>Agregar usuario</button>
                                        </div>
                                    </div>
                                </div>                                        
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100"></div>
            </div>
        )
    }
}