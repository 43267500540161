import React from 'react';
import $ from 'jquery';
import 'round-slider';//http://roundsliderui.com
import 'round-slider/dist/roundslider.min.css';

class RoundSlider extends React.Component {

    componentDidMount() {
        this.$rsEle = $(this.refs.roundSlider);
        const options = Object.assign({ svgMode: false }, this.props);
        this.$rsEle[0].id = this.props.id;
        this.$rsEle.roundSlider(options);
    }

    // This way, ReactJS will never re-render our component,
    // and jQuery will be responsible for all updates.
    shouldComponentUpdate() {
        return false;
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        // here we have considered the value property alone
        // if you are going to dynamically update any other properties
        // then consider those properties also here

        if (nextProps.value !== this.props.value) {
            this.$rsEle.roundSlider("option", "value", nextProps.value);
        }
    }

    render() {
        return (
            <>
                <div ref="roundSlider"></div>
            </>
        );
    }

    componentWillUnmount() {
        this.$rsEle.roundSlider('destroy');
    }
};

// Optional: set the default props, in case none are passed
RoundSlider.defaultProps = {
    width: 22,
    radius: 100,
    editableTooltip: false,
    circleShape: "pie",
    sliderType: "min-range",
    handleShape: "dot",
    lineCap: "round",
    startAngle: 315,
    step: "0.5",
    max: "35",
    drag: function (args) {
        // handle the drag event here
    },
    change: function (args) {
        // handle the change event here
    },
    tooltipFormat: function (args) {
        return args.value + "&deg;C<br /><small>" + args.options.setTemp + "&deg;C</small>";
    },
};

export default RoundSlider;