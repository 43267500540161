import React, { useState, useEffect } from "react";
import Parser from 'html-react-parser';

/*Components of Automated-Life*/
import Header from "../Shared/Header";
import { useAuthValue } from "../Shared/AuthProvider";
import Loading from "../Shared/Loading";
import { getDatabase, GetNotificationsById, GetTopNotifications } from "automated-provider";

/*Componentes of FireBase*/
import { ref, onChildAdded } from "firebase/database";

export default function Notifications(parameters) {
    const { currentUser } = useAuthValue();
    const [notifications, setNotifications] = useState({});
    const [deviceSensor, setDevice] = useState(parameters.deviceSensor);
    const [lastSize, setLastSize] = useState(parameters.lastSize !== undefined ? Number.parseInt(parameters.lastSize.split('-')[1]) : 0);

    const [devicesSensores, setDevicesSensores] = useState([]);
    const [notificationsGlobalAndFilter, setNotificationsGlobalAndFilter] = useState([]);
    const [deviceSensorFilter, setDeviceSensorFilter] = useState(null);
    const [loadNotification, setLoadNotification] = useState(true);

    useEffect(() => {
        setLoadNotification(true);
        function queryDevicesSensores() {
            setDevicesSensores([]);
            onChildAdded(ref(getDatabase(), 'cliente-pins/' + currentUser.uid + '/'), (data) => {
                let dataTemp = data.val();
                dataTemp.key = data.key;
                dataTemp.type = "device";
                GetNotificationsById(currentUser.uid, data.key).then((responseNotifications) => {
                    dataTemp.notificationsSize = responseNotifications.length;
                    
                    if (dataTemp.visible)
                        setDevicesSensores(devicesSensores => [...devicesSensores, dataTemp]);
                });
            });
            onChildAdded(ref(getDatabase(), 'cliente-sensores/' + currentUser.uid + '/'), (data) => {
                let dataTemp = data.val();
                dataTemp.key = data.key;
                dataTemp.type = "sensor";
                GetNotificationsById(currentUser.uid, data.key).then((responseNotifications) => {
                    dataTemp.notificationsSize = responseNotifications.length;
                    if (dataTemp.visible)
                        setDevicesSensores(devicesSensores => [...devicesSensores, dataTemp]);
                });
            });
        };

        if (lastSize !== undefined && Number.isInteger(lastSize) && (Number.parseInt(lastSize) > 0)) {
            GetNotificationsById(currentUser.uid, deviceSensor.key, lastSize).then((responseNotifications) => {
                setNotifications(responseNotifications);
                setLoadNotification(false);
            });
        } else {
            queryDevicesSensores();

            GetTopNotifications(currentUser.uid, 100).then((responseNotifications) => {
                setNotificationsGlobalAndFilter(responseNotifications);
                setLoadNotification(false);
            });
        }        
    }, [])

    const filterNotification = (dsid, nameDeviceSensor) => {
        setLoadNotification(true);
        setDeviceSensorFilter(nameDeviceSensor);
        setNotificationsGlobalAndFilter([]);
        GetNotificationsById(currentUser.uid, dsid, 100).then((responseNotifications) => {
            setNotificationsGlobalAndFilter(responseNotifications);
            setLoadNotification(false);
        });
    };

    const removeFilterNotification = () => {
        setLoadNotification(true);
        setDeviceSensorFilter(null);
        setNotificationsGlobalAndFilter([]);
        GetTopNotifications(currentUser.uid, 100).then((responseNotifications) => {
            setNotificationsGlobalAndFilter(responseNotifications);
            setLoadNotification(false);
        });
    };

    if (loadNotification) {
        if (lastSize !== undefined && Number.isInteger(lastSize) && (Number.parseInt(lastSize) > 0)) {
            return (<Loading />);
        }else{
            return (<div className="container"><Header user={currentUser} /><Loading /></div>);
        }
    } else if (notifications.length !== undefined) {
        if (notifications.length > 0) {
            return (
                <div className="col-12">
                    <span>Últimas {lastSize} notificaciones</span>
                    <ul className="list-notification">
                        {
                            notifications.map((notification) => {
                                return (
                                    <li key={notification.key + "_" + notification.fecha}>
                                        <div className="content">
                                            <div className={notification.descripcion.indexOf("desactivado") > 0 ? "title" : "title activado"}>
                                                {notification.fecha}
                                            </div>
                                            <div className="description">
                                                {Parser(notification.descripcion)}
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            )
        } else {
            return (
                <div className="col-12">
                    <span>No existen notificaciones asociadas</span>   
                </div>
            )
        }
    } else {
        return (
            <div className="container">
                <Header user={currentUser} />
                <div className="w-100"></div>
                <div className="row">
                    <div className="w-100"></div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                        <p className="name page-title text-sm-start text-center">Últimas Notificaciones</p>
                        {
                            deviceSensorFilter !== null ? (<><div className="name page-subtitle text-xs-center">de {deviceSensorFilter} <sup className="notification-filter-close" onClick={() => removeFilterNotification()}>Mostrar todas</sup></div></>) : null
                        }
                        {notificationsGlobalAndFilter.length > 0 ?
                            (<ul className="list-notification">
                                {
                                    notificationsGlobalAndFilter.map((notification) => {
                                        return (
                                            <li key={notification.key + "_" + notification.fecha}>
                                                <div className="content">
                                                    <div className={notification.descripcion.indexOf("desactivado") > 0 ? "title" : "title activado"}>
                                                        {notification.fecha}
                                                    </div>
                                                    <div className="description">
                                                        {Parser(notification.descripcion)}
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>)
                            : <ul className="list-notification"><span>No existen notificaciones asociadas</span></ul>
                        }
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-8">
                        <div className="row">
                            {
                                devicesSensores.map((deviceSensor) => {
                                    return (
                                        <div key={deviceSensor.key + "_" + deviceSensor.fecha} className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2">
                                            <div className={deviceSensor.type === "device" ? "notification-sphere notification-device" : "notification-sphere notification-sensor"} onClick={() => filterNotification(deviceSensor.key, deviceSensor.nombre)}>
                                                <div className="notification-count">{deviceSensor.notificationsSize}</div>
                                                <div className="notification-name">{deviceSensor.nombre}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>);
    }
}