import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faClose } from '@fortawesome/free-solid-svg-icons'
import MediaQuery from 'react-responsive'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


/*Components of Automated-Life*/
import { useAuthValue } from "../Shared/AuthProvider";
import Loading from "../Shared/Loading";
import { GetProgrammings, SaveAutomaticProgramingDevice, DeleteProgrammings, WebPushNotification, customDatesFormatInput } from "automated-provider";

export default function AutomaticProgramming(parameters) {
    const { currentUser } = useAuthValue();
    const [programmings, setProgrammings] = useState([]);
    const [programming, setProgramming] = useState({key : uuid(), config : {hora_inicio : "00:00", hora_fin : "00:00", fecha_inicioFormat : "", fecha_finFormat : "", lunes : false, martes : false, miercoles : false, jueves : false, viernes : false, sabado : false, domingo : false}});

    const [loadProgramming, setLoadProgramming] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const closeModal = async () => {setShowModal(false)};

    useEffect(() => {
        if (currentUser !== null) {
            queryProgrammings();
            setShowModal(false);
        } 
    }, [])

    const queryProgrammings = async () => {    
        setProgrammings([]);
        GetProgrammings(currentUser.uid, parameters.type, parameters.id).then((responseProgrammings) => {
            setProgrammings(responseProgrammings);
            setLoadProgramming(false);
        });            
    };

    const editar = async (e, key) => {       
        var programEdit = programmings.filter(x => x.key === key)[0];        
        if (programEdit !== null || programEdit !== undefined) {
            programEdit.config['fecha_inicioFormat'] = customDatesFormatInput(JSON.parse(JSON.stringify(programEdit.config.fecha_inicio)));
            programEdit.config['fecha_finFormat'] = customDatesFormatInput(JSON.parse(JSON.stringify(programEdit.config.fecha_fin)));

            if(programEdit.config.dias.indexOf('L') > -1)
                programEdit.config.lunes = true;
            else
                programEdit.config.lunes = false;
            if(programEdit.config.dias.indexOf('M') > -1)
                programEdit.config.martes = true;
            else
                programEdit.config.martes = false;
            if(programEdit.config.dias.indexOf('X') > -1)
                programEdit.config.miercoles = true;
            else
                programEdit.config.miercoles = false;
            if(programEdit.config.dias.indexOf('J') > -1)
                programEdit.config.jueves = true;
            else
                programEdit.config.jueves = false;
            if(programEdit.config.dias.indexOf('V') > -1)
                programEdit.config.viernes = true;
            else
                programEdit.config.viernes = false;
            if(programEdit.config.dias.indexOf('S') > -1)
                programEdit.config.sabado = true;
            else
                programEdit.config.sabado = false;
            if(programEdit.config.dias.indexOf('D') > -1)
                programEdit.config.domingo = true;
            else
                programEdit.config.domingo = false;

            setProgramming(programEdit);
            setShowModal(true);
        }
    };

    const eliminar = async (e, key) => {        
        DeleteProgrammings(currentUser.uid, parameters.type, parameters.id, key);
        setLoadProgramming(true);
        WebPushNotification("La activación automática se ha eliminado correctamente", "Activación automática", "success");        
        queryProgrammings();
    };

    const guardar = async (e, key) => {
        //delete programming['fecha_finFormat'];
        //delete programming['fecha_inicioFormat'];

        programming.config.dias = "";
        if(programming.config.lunes){
            programming.config.dias = "L";            
        }
        if(programming.config.martes){
            if(programming.dias !== "")
                programming.config.dias = programming.config.dias + " M";
            else
                programming.config.dias = "M";                
            
        }
        if(programming.config.miercoles){
            if(programming.config.dias !== "")
                programming.config.dias = programming.config.dias + " X";
            else
                programming.config.dias = "X";            
        }
        if(programming.config.jueves){
            if(programming.config.dias !== "")
                programming.config.dias = programming.config.dias + " J";
            else
                programming.config.dias = "J";
        }
        if(programming.config.viernes){
            if(programming.config.dias !== "")
                programming.config.dias = programming.config.dias + " V";
            else
                programming.config.dias = "V";
        }
        if(programming.config.sabado){
            if(programming.config.dias !== "")
                programming.config.dias = programming.config.dias + " S";
            else
                programming.config.dias = "S";            
        }
        if(programming.config.domingo){
            if(programming.config.dias !== "")
                programming.config.dias = programming.config.dias + " D";
            else
                programming.config.dias = "D";            
        }

        if(programming.config.dias === ""){
            setShowModal(true);
            WebPushNotification("Es necesario indicar al menos un día de la semana que se activara la activación automática", "Activación automática", "warning");
            return;
        }

        delete programming.config["lunes"];        
        delete programming.config["martes"];
        delete programming.config["miercoles"];
        delete programming.config["jueves"];
        delete programming.config["viernes"];
        delete programming.config["sabado"];
        delete programming.config["domingo"];
                
        if(programming.config.fecha_inicio !== undefined && programming.config.fecha_inicio !== "--"){
            if(programming.config.fecha_inicio.indexOf('-') > -1){
                programming.config.fecha_inicio = programming.config.fecha_inicio.replaceAll("-","/");
                programming.config.fecha_inicio = new Date(programming.config.fecha_inicio.split('/')[0],programming.config.fecha_inicio.split('/')[1]-1,programming.config.fecha_inicio.split('/')[2]).toLocaleDateString("es-ES");
            }else{
                programming.config.fecha_inicio = new Date(programming.config.fecha_inicio.split('/')[2],programming.config.fecha_inicio.split('/')[1]-1,programming.config.fecha_inicio.split('/')[0]).toLocaleDateString("es-ES");
            }
        }else{
            programming.config.fecha_inicio = "--";
        }
        if(programming.config.fecha_fin !== undefined && programming.config.fecha_fin !== "--"){
            if(programming.config.fecha_fin.indexOf('-') > -1){
                programming.config.fecha_fin = programming.config.fecha_fin.replaceAll("-","/");
                programming.config.fecha_fin = new Date(programming.config.fecha_fin.split('/')[0],programming.config.fecha_fin.split('/')[1]-1,programming.config.fecha_fin.split('/')[2]).toLocaleDateString("es-ES");
            }else{
                programming.config.fecha_fin = new Date(programming.config.fecha_fin.split('/')[2],programming.config.fecha_fin.split('/')[1]-1,programming.config.fecha_fin.split('/')[0]).toLocaleDateString("es-ES");
            }
        }else{
            programming.config.fecha_fin = "--";
        }

        if(programming.config.fecha_inicio !== undefined && programming.config.fecha_inicio !== "--" && programming.config.fecha_fin !== undefined && programming.config.fecha_fin !== "--"){
            if(new Date(programming.config.fecha_inicio) > new Date(programming.config.fecha_fin)){
                setShowModal(true);
                WebPushNotification("La fecha de finalización tiene que ser mayor que la fecha de inicio en la activación automática", "Activación automática", "warning");
                return;
            }
        }

        debugger;
        SaveAutomaticProgramingDevice(currentUser.uid, parameters.id, programming).then((response) => {   
            setLoadProgramming(true);
            setShowModal(false);         
            WebPushNotification("La activación automática se ha guardado correctamente", "Activación automática", "success");            
        });
        queryProgrammings();
    };

    const nueva = async (e) => {         
        setProgramming({key : uuid(), config : {hora_inicio : "00:00", hora_fin : "00:00", fecha_inicioFormat : "", fecha_finFormat : "", lunes : false, martes : false, miercoles : false, jueves : false, viernes : false, sabado : false, domingo : false}});  
        setShowModal(true);      
    };

    const programmingHandleChange = async (e) => {
        if (e.target.name.includes("hora")) {
            if (e.target.value.length === 5) {
                if (e.target.value.match("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$") !== null) {
                    programming.config[e.target.name] = e.target.value;
                } else {
                    e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                    programming.config[e.target.name] = null;
                }
            } else {
                if (!Number.isInteger(parseInt(e.target.value.substring(e.target.value.length, e.target.value.length - 1))) && e.target.value.substring(e.target.value.length, e.target.value.length - 1) !== ":") {
                    e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                    programming.config[e.target.name] = null;
                }else if (e.target.value.length > 5){
                    e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                    programming.config[e.target.name] = null;
                }
            }
        } else if (e.target.name.includes("lunes")
            || e.target.name.includes("martes")
            || e.target.name.includes("miercoles")
            || e.target.name.includes("jueves")
            || e.target.name.includes("viernes")
            || e.target.name.includes("sabado")
            || e.target.name.includes("domingo")) {
            programming.config[e.target.name] = e.currentTarget.checked;
        } else {
            programming.config[e.target.name] = e.target.value;
        }

        setProgramming(programming);  
    };

    if (loadProgramming) {
        return (<Loading />);        
    } else {        
        let programmingsHtml = (
            <>
                <MediaQuery maxWidth={1200}>                     
                    {
                        programmings.map((programacion) => {                
                            return (
                                <div className="row list-automatic-programming-content" key={programacion.key}>                                 
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <label>Fecha Inicio</label>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <label>{programacion.config.fecha_inicio}</label>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <label>Fecha Fin</label>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <label>{programacion.config.fecha_fin}</label>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <label>Días</label>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <label className="automatic-programming-days">{programacion.config.dias}</label>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <label>Hora Inicio</label>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <label>{programacion.config.hora_inicio}</label>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <label>Hora Fin</label>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <label>{programacion.config.hora_fin}</label>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="btn-group actions" role="group">
                                            <button type="button" className="btn btn-sm btn-default" onClick={(e) => editar(e, programacion.key)}><FontAwesomeIcon icon={faEdit} size="1x" /></button>
                                            <button type="button" className="btn btn-sm btn-danger" onClick={(e) => eliminar(e, programacion.key)}><FontAwesomeIcon icon={faClose} size="1x" /></button>
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                            <div className="divider"></div>
                                    </div>
                                </div>
                            )
                        })
                    }                  
                </MediaQuery>
                <MediaQuery minWidth={1200}>
                    <div className="row">
                        <div className="col-lg-2">                        
                            <label>Fecha Inicio</label>
                        </div>
                        <div className="col-lg-2">
                            <label>Fecha Fin</label>
                        </div>
                        <div className="col-lg-2">
                            <label>Días</label>
                        </div>
                        <div className="col-lg-2">
                            <label>Hora Inicio</label>
                        </div>
                        <div className="col-lg-2">
                            <label>Hora Fin</label>
                        </div>
                        <div className="col-lg-2">
                            &nbsp;
                        </div>
                        {
                            programmings.map((programacion) => {
                                return (
                                    <div className="row list-automatic-programming-content" key={programacion.key}>
                                        <div className="col-lg-2">
                                            <label>{programacion.config.fecha_inicio}</label>
                                        </div>
                                        <div className="col-lg-2">
                                            <label>{programacion.config.fecha_fin}</label>
                                        </div>
                                        <div className="col-lg-2">
                                            <label className="automatic-programming-days">{programacion.config.dias}</label>
                                        </div>
                                        <div className="col-lg-2">
                                            <label>{programacion.config.hora_inicio}</label>
                                        </div>
                                        <div className="col-lg-2">
                                            <label>{programacion.config.hora_fin}</label>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="btn-group actions" role="group">
                                                <Button className="btn btn-sm btn-default" onClick={(e) => editar(e, programacion.key)}><FontAwesomeIcon icon={faEdit} size="1x" /></Button>
                                                <Button className="btn btn-sm btn-danger" onClick={(e) => eliminar(e, programacion.key)}><FontAwesomeIcon icon={faClose} size="1x" /></Button>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="divider"></div>
                                        </div>
                                    </div>
                                )
                            })
                        } 
                    </div>
                </MediaQuery>
            </>
        );
        let notFoundProgrammingsHtml = (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <span>No existen programaciones asociadas</span>   
            </div>
        );
        let newProgrammingHtml = (
            <>
                <div className="w-100"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="text-end">
                        <Button className="btn btn-save" onClick={(e) => nueva(e)}>Nueva activación automática</Button>
                    </div>
                    <Modal show={showModal} onHide={closeModal} keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Activación automática</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12 col-sm- 6col-md-6 col-lg-6 form-group has-feedback">
                                    <label className="control-label">Fecha de Inicio</label>
                                    <input type="date"
                                        className="form-control"
                                        placeholder="aaaa-mm-dd"
                                        name="fecha_inicio"
                                        id={programming.key + ".fecha_inicio"}
                                        defaultValue={programming.config.fecha_inicioFormat}
                                        onChange={(e) => programmingHandleChange(e)} />
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                    <label className="control-label">Fecha de finalización</label>
                                    <input type="date"
                                        className="form-control"
                                        placeholder="aaaa-mm-dd"
                                        name="fecha_fin"
                                        defaultValue={programming.config.fecha_finFormat}
                                        id={programming.key + ".fecha_fin"}
                                        onChange={(e) => programmingHandleChange(e)} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 form-group has-feedback">
                                <div className="form-check form-switch form-check-inline">
                                    <input type="checkbox" id={programming.key + ".lunes"} name="lunes" defaultChecked={!!programming.config.lunes === true ? true : false} onChange={(e) => { programmingHandleChange(e) }} className="form-check-input" />
                                    <label className="form-check-label">Lunes</label>
                                    </div>
                                    <div className="form-check form-switch form-check-inline">
                                        <input type="checkbox" id={programming.key + ".martes"} name="martes" defaultChecked={!!programming.config.martes === true ? true : false} onChange={(e) => { programmingHandleChange(e) }} className="form-check-input" />
                                        <label className="form-check-label">Martes</label>
                                    </div>
                                    <div className="form-check form-switch form-check-inline">
                                        <input type="checkbox" id={programming.key + ".miercoles"} name="miercoles" defaultChecked={!!programming.config.miercoles === true ? true : false} onChange={(e) => { programmingHandleChange(e) }} className="form-check-input" />
                                        <label className="form-check-label">Miercoles</label>
                                    </div>
                                    <div className="form-check form-switch form-check-inline">
                                        <input type="checkbox" id={programming.key + ".jueves"} name="jueves" defaultChecked={!!programming.config.jueves === true ? true : false} onChange={(e) => { programmingHandleChange(e) }} className="form-check-input" />
                                        <label className="form-check-label">Jueves</label>
                                    </div>
                                    <div className="form-check form-switch form-check-inline">
                                        <input type="checkbox" id={programming.key + ".viernes"} name="viernes" defaultChecked={!!programming.config.viernes === true ? true : false} onChange={(e) => { programmingHandleChange(e) }} className="form-check-input" />
                                        <label className="form-check-label">Viernes</label>
                                    </div>
                                    <div className="form-check form-switch form-check-inline">
                                        <input type="checkbox" id={programming.key + ".sabado"} name="sabado" defaultChecked={!!programming.config.sabado === true ? true : false} onChange={(e) => { programmingHandleChange(e) }} className="form-check-input" />
                                        <label className="form-check-label">Sábado</label>
                                    </div>
                                    <div className="form-check form-switch form-check-inline">
                                        <input type="checkbox" id={programming.key + ".domingo"} name="domingo" defaultChecked={!!programming.config.domingo === true ? true : false} onChange={(e) => { programmingHandleChange(e) }} className="form-check-input" />
                                        <label className="form-check-label">Domingo</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                    <label className="control-label">Hora de Inicio</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="HH:MM"
                                        name="hora_inicio"
                                        defaultValue={programming.config.hora_inicio}
                                        id={programming.key + ".hora_inicio"}
                                        onChange={(e) => programmingHandleChange(e)} />
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 form-group has-feedback">
                                    <label className="control-label">Hora de finalización</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="HH:MM"
                                        name="hora_fin"
                                        defaultValue={programming.config.hora_fin}
                                        id={programming.key + ".hora_fin"}
                                        onChange={(e) => programmingHandleChange(e)} />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-secondary" onClick={closeModal}>Cerrar</Button>
                            <Button className="btn btn-save" onClick={(e) => { guardar(e, programming.key) } }>Guardar activación</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        );

        if (programmings.length > 0) {
            return (
                <>
                    {programmingsHtml}
                    {newProgrammingHtml}
                </>
            )
        }else{
            return (
                <>
                    {notFoundProgrammingsHtml}
                    {newProgrammingHtml}
                </>
            )                 
        }        
    }
}