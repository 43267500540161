import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

/*Components of Automated-Life*/
import Devices from "../Devices/Devices";
import Header from "../Shared/Header";
import { useAuthValue } from "../Shared/AuthProvider";
import Loading from "../Shared/Loading";
import { LoginUser, ResetPassword, VerfificationEmail } from "automated-provider";

export default function Login(parameters) {
	const { currentUser } = useAuthValue();
	const [loadController, setLoadController] = useState(true);
	//const abortController = new AbortController();

	const [state, setState] = useState({ login: { userName: '', email: '', password: '', error: '', logIn: false, recoveryPassword: false } });

	useEffect(() => {
		setLoadController(false);
	}, [])


	const handlerInput = (event) => {
		const field = event.target.name;
		const login = state.login;
		login[field] = event.target.value;

		setState({ login });
	}

	const signIn = (props) => {
		setLoadController(true);
		let _login = state.login;

		if (state.login.email !== "" && state.login.password !== "") {
			LoginUser(state.login.email, state.login.password).then(responseLogin => {
				console.log("responselogin : " + responseLogin);
				if (responseLogin._tokenResponse) {
					if (responseLogin.user.emailVerified) {
						_login.logIn = true;
						window.location.reload();
					} else {
						VerfificationEmail(responseLogin.user).then(function () {
							_login.logIn = false;
							_login.email = "";
							_login.password = "";
							_login.error = "Se ha enviado un email de verificación de cuenta a su dirección de correo electrónico. Verifique el correo y vuelva a iniciar sesión.";
						}).catch(function (err) {
							_login.logIn = false;
							_login.error = "En estos momentos el sistema no esta disponible, intentelo de nuevo más tarde.";
							console.log(err);
						});
					}
				} else {
					_login.logIn = false;
					_login.email = "";
					_login.password = "";
					_login.error = "En estos momentos el sistema no esta disponible, intentelo de nuevo más tarde.";
				}
			}).catch((err) => {
				_login.logIn = false;
				console.log("Error login : " + err.code);
				if (err.code === "auth/user-not-found" || err.code === "auth/wrong-password") {
					_login.error = "El email/usuario ó contraseña no son válidos, intentelo de nuevo.";
				} else {
					_login.error = "En estos momentos el sistema no esta disponible, intentelo de nuevo más tarde.";
				}

				setState({ login: _login });
			}); 				
		}

		setState({ login: _login });
		setLoadController(false);
	};

	const recoveryPasswordClick = (showHide) => {
		setLoadController(true);
		let _login = state.login;
		_login.error = "";
		_login.recoveryPassword = showHide;
		setState({ login: _login });
		setLoadController(false);
	};

	const resetPassword = (props) => {
		let _login = state.login;
		setLoadController(true);
		
		try {
			ResetPassword(state.login.email);
			//sendPasswordResetEmail(auth, state.login.email);
			_login["error"] = "Se ha enviado un email de restauración de contraseña a su dirección de correo electrónico.\n\n Cambie la contraseña desde el enlace del email recibido y vuelva a iniciar sesión.";
		} catch (err) {
			_login["logIn"] = false;
			_login["error"] = "En estos momentos el sistema no esta disponible, intentelo de nuevo más tarde.";
			console.error(err);
		}

		setState({ login: _login });
		setLoadController(false);
	}

	if (window.location.pathname !== "/app/login" || currentUser === null) {
		if (loadController) {
			return (<Loading />);
		} if (state.login.recoveryPassword) {
			return (
				<div className="container">
					<Header user={null} />
					<div className="col-xs-12 col-sm-4 offset-sm-4 col-md-4  offset-md-4 col-lg-4 offset-lg-4">
						<div className="row panel-login">
							<div className="col-12 form-group has-feedback">
								<label className="control-label">Email de recuperación</label>
								<input type="text"
									className="form-control has-feedback-left"
									id="email"
									placeholder="Email para recuperar contraseña"
									name="email"
									value={state.login.email}
									onChange={handlerInput} />
							</div>
							<div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-end">
								<button className="btn btn-default submit" tooltip="Cancelar restaurar contraseña" type="submit" onClick={() => recoveryPasswordClick(false)}>Cancelar</button>
							</div>
							<div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-left">
								<button className="btn btn-default submit" tooltip="Recordar restaurar contraseña" type="submit" onClick={() => resetPassword()}>Recordar</button>
							</div>
							<div className="col-12 text-center">
								<div className="input-field">
									<p className="white-text restoreText">{state.login.error}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else if (currentUser === null && state.login.logIn === false) {
			return (
				<div className="container">
					<Header user={currentUser} />
					<div className="col-xs-12 col-sm-4 offset-sm-4 col-md-4 offset-md-4 col-lg-4 offset-lg-4">
						<div className="row panel-login">
							<div className="col-12 form-group has-feedback">
								<label className="control-label">Email</label>
								<input type="text"
									className="form-control has-feedback-left"
									id="email"
									name="email"
									placeholder="Email"
									value={state.login.email}
									onChange={handlerInput} />
							</div>
							<div className="col-12 form-group has-feedback">
								<label className="control-label">Contraseña</label>
								<input type="password"
									className="form-control has-feedback-left"
									id="password"
									name="password"
									placeholder="Contraseña"
									value={state.login.password}
									onChange={handlerInput} />
							</div>
							<div className="col-12">
								<span className="invalid-form-error-message">{state.login.error}</span>
							</div>
							<div className="col-12 text-center">
								<a className="btn btn-default submit" tooltip="Iniciar sesión" onClick={() => signIn()}>Iniciar</a>
							</div>
							<div className="col-12  text-center">
								<span className="blue-grey-text text-lighten-4">¿No recuerdas la contraseña?</span><a onClick={() => recoveryPasswordClick(true)} className="white-text modal-trigger"> Recordar contraseña.</a>
							</div>
						</div >
					</div >
				</div>
			);
		} else {
			return (null);
		}
	} else {
		return (<Devices />)
    }
}