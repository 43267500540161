import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./styles.css";

import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/*Components of Automated-Life*/

import { GetAuthenticatedUser } from "automated-provider";
import { AuthProvider } from "./components/Shared/AuthProvider";
import Profile from "./components/Profile/Profile";
import Login from "./components/Login/Login";
import Adm from "./components/Adm/Adm";
import Devices from "./components/Devices/Devices";
import Notifications from "./components/Notifications/Notifications";
import Statistics from "./components/Statistics/Statistics";
import Public from "./components/Public/Public";
import NotFound from "./components/Shared/NotFound";
import Loading from "./components/Shared/Loading";

export default function App() {
	const abortController = new AbortController();
	const [currentUser, setCurrentUser] = useState(null);
	const [loadController, setLoadController] = useState(true);

	useEffect(() => {
		GetAuthenticatedUser().then((responseUser) => {
			if(responseUser != undefined && responseUser != null)
			{
				setCurrentUser(responseUser);
			}		
			setLoadController(false);
        });
		/*return () => {
			setCurrentUser({});
		};*/
	}, [])
	

	if (loadController) {
		return (
			<div className="container">
				<Router basename={process.env.PUBLIC_URL}>
					<AuthProvider value={{ currentUser }}>
						<Routes>
							<Route path="/app/*" element={<Loading />} />
							<Route exact path="/" element={<Public />} />
						</Routes>
					</AuthProvider>
				</Router>
			</div>
		);
	} else if (currentUser != null && currentUser.uid != null && currentUser.emailVerified) {
		if (window.location.pathname == "/app/" || window.location.pathname == "/app") {
			return (
				<div className="container">
					<Router basename={process.env.PUBLIC_URL}>
						<AuthProvider value={{ currentUser }}>
							<Routes>						
								<Route exact path="/" element={<Public />} />
								<Route exact path="/app/" element={<Devices />} />
								<Route exact path="/app/login" element={<Devices />} />
								<Route exact path="/app/perfil" element={<Profile />} />
								<Route exact path="/app/dispositivos" element={<Devices />} />
								<Route exact path="/app/notificaciones" element={<Notifications />} />
								<Route exact path="/app/estadisticas" element={<Statistics />} />
								<Route exact path="/app/admin" element={<Adm />} />
								<Route path="*" element={<NotFound />} />
							</Routes>
						</AuthProvider>
					</Router>
				</div>
			);
		} else {
			return (
				<>
					<Router basename={process.env.PUBLIC_URL}>
						<AuthProvider value={{ currentUser }}>
							<Routes>
								<Route exact path="/" element={<Public />} />
								<Route exact path="/app/" element={<Devices />} />
								<Route exact path="/app/login" element={<Devices />} />
								<Route exact path="/app/perfil" element={<Profile />} />
								<Route exact path="/app/dispositivos" element={<Devices />} />
								<Route exact path="/app/notificaciones" element={<Notifications />} />
								<Route exact path="/app/estadisticas" element={<Statistics />} />
								<Route exact path="/app/admin" element={<Adm />} />
								<Route path="*" element={<NotFound />} />
							</Routes>
						</AuthProvider>
					</Router>
				</>
			);
        }
	} else {
		if (window.location.pathname.indexOf("app") > 0) {			
			return (
				<div className="container">
					<Router basename={process.env.PUBLIC_URL}>
						<AuthProvider value={{ currentUser }}>
							<Routes>
								<Route path="/app" element={<Login />} />
								<Route path="/app/*" element={<Login />} />
								<Route exact path="/" element={<Public />} />
							</Routes>
						</AuthProvider>
					</Router>
				</div>
			);
		} else {
			return (
				<>
					<Router basename={process.env.PUBLIC_URL}>
						<AuthProvider value={{ currentUser }}>
							<Routes>
								<Route exact path="" element={<Public />} />
								<Route exact path="/" element={<Public />} />
							</Routes>
						</AuthProvider>
					</Router>
				</>
			);
        }		
    }
}