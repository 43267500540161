import React, { useState, useEffect } from "react";
import { NotificationContainer } from 'react-notifications';//https://www.npmjs.com/package/react-notifications

/*Componentes of Automated-Life*/
import Menu from "../Menu/Menu";


export default function Header(parameters) {
    if (parameters != null && parameters.user != null) {
        return (
            <>
            <div className="row text-center">
                <div className="w-100"></div>
                <div className="logo"><a href="/user">&nbsp;</a></div>
                <Menu user={ parameters.user } />
                </div>

                <NotificationContainer />
            </>
        );
    } else {
        return (
            <div className="row text-center">
                <div className="w-100"></div>
                <div className="logo"><a href="/user">&nbsp;</a></div>
            </div>
        );
    }
}