import 'react-notifications/lib/notifications.css';

import React, { useContext, useState, useEffect, Suspense } from "react";

/*Components of Automated-Life*/
import Header from "../Shared/Header";
import { useAuthValue } from "../Shared/AuthProvider";
import Loading from "../Shared/Loading";
import DeviceTemp from "./DeviceTemp";
import DeviceCamera from "./DeviceCamera";
import DeviceRelay from "./DeviceRelay";
import SensorTemp from "./SensorTemp";
import SensorBar from "./SensorBar";
import SensorHumo from "./SensorHumo";
import SensorNivel from "./SensorNivel";
import { getDatabase, GetUserFireBase, GetDevices, GetSensors } from "automated-provider";

/*Componentes of FireBase*/
import { ref, onChildAdded, onChildChanged} from "firebase/database";

export default function Devices(parameters) {
    const { currentUser } = useAuthValue();
    const [user, setUser] = useState("user_empty");
    const [devices, setDevices] = useState([]);
    const [sensores, setSensores] = useState([]);
    const [loadDevices, setLoadDevices] = useState(true);
    
    useEffect(() => {
        setLoadDevices(true);
        if (currentUser != null) {
            let dataUser;
            GetUserFireBase(currentUser.uid).then((responseUser) => {
                dataUser = responseUser;
                dataUser.key = currentUser.uid;
                //Se cargan primero sensores antes que dispositivos por la dependencia en algunos dispositivos de entrada asociado a un sensor
                var promises = [];
                promises.push(new Promise(async (resolve, reject) => {
                    try {
                        setSensores([]);                        
                        GetSensors().then((data) => { 
                            var sensoresTemp = [];  
                            Object.keys(data.sensors).forEach(function(index) {
                                if (data.sensors[index].visible)
                                    sensoresTemp.push(data.sensors[index]);
                              });                    
                            resolve(sensoresTemp);
                        });                       
                    } catch (e) {
                        reject({ "sensores-process": false });
                    }
                }));
                promises.push(new Promise(async (resolve, reject) => {
                    try {
                        setDevices([]);
                        GetDevices().then((data) => {
                            var devicesTemp = [];  
                            Object.keys(data.devices).forEach(function(index) {
                                if (data.devices[index].visible)
                                    devicesTemp.push(data.devices[index]);
                              });                            
                            resolve(devicesTemp);
                        });     
                    } catch (e) {
                        reject({ "devices-process": false });
                    }
                }));

                Promise.all(promises).then((devicesAndSensors) => {
                    setSensores(sensores => devicesAndSensors[0]);
                    setDevices(devices => devicesAndSensors[1]);                    
                    setUser(dataUser);
                    setLoadDevices(false);
                });
                
                /*onChildAdded(ref(getDatabase(), 'cliente-sensores/' + currentUser.uid + '/'), (data) => {
                    let dataTemp = data.val();
                    dataTemp.key = data.key;
    
                    if (dataTemp.visible)
                        setSensores(sensores => [...sensores, dataTemp]);
                });*/
                
                /*onChildAdded(ref(getDatabase(), 'cliente-pins/' + currentUser.uid + '/'), (data) => {
                    let dataTemp = data.val();
                    dataTemp.key = data.key;
                    
                    if (dataTemp.visible)
                        setDevices(devices => [...devices, dataTemp]);
                });*/
            });            
        }        
    }, [])

    if (currentUser != null) {
        onChildChanged(ref(getDatabase(), 'cliente-pins/' + currentUser.uid + '/'), (data) => {
            var deviceUpdated = devices.filter(k => k.key == data.key);
            if (deviceUpdated) {
                deviceUpdated = data.val();
            }
        });
        onChildChanged(ref(getDatabase(), 'cliente-sensores/' + currentUser.uid + '/'), (data) => {
            var sensorUpdated = sensores.filter(k => k.key == data.key);
            if (sensorUpdated) {
                sensorUpdated = data.val();
            }
        });
    }

    if (loadDevices) {
        return (<><Header user={currentUser} /><Loading /></>);
    } else {
        return (
            <div className="container">
                <Header user={currentUser} />
                <div className="w-100"></div>
                <div className="row d-none d-sm-flex">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <p className="name page-title text-sm-start">Dispositivos</p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <p className="name page-title text-sm-start">Sensores</p>
                    </div>
                </div>
                <div className="row d-sm-none">
                    <p className="name page-title text-center">Dispositivos</p>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="row">
                        {
                            devices.map((device) => {
                                return (device.entrada_salida.tipo == 'temperatura' ?
                                    (
                                        <DeviceTemp key={device.key} device={device} user={user} />
                                    ) : device.entrada_salida.tipo == 'camara' ? (
                                        <DeviceCamera key={device.key} device={device} user={user} />
                                    ) : device.entrada_salida.tipo == 'relay' ? (
                                        <DeviceRelay key={device.key} device={device} user={user} />
                                    ) :
                                    <DeviceTemp key={device.key} device={device} user={user} />
                                )
                            })
                        }
                        </div>
                    </div>
                    <div className="col-12 d-sm-none">
                        <p className="name page-title text-center">Sensores</p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="row">
                        {
                        sensores.map((sensor) => {
                                return (sensor.tipo_sensor == 'temperatura_in' ?
                                    (
                                        <SensorTemp key={sensor.key} sensor={sensor} user={user} />
                                    ) : sensor.tipo_sensor == 'presion' ? (
                                        <SensorBar key={sensor.key} sensor={sensor} user={user} />
                                    ) : sensor.tipo_sensor == 'humo' ? (
                                        <SensorHumo key={sensor.key} sensor={sensor} user={user}/>
                                    ) : sensor.tipo_sensor == 'nivel' ? (
                                        <SensorNivel key={sensor.key} sensor={sensor} user={user} />
                                    ) :
                                        <SensorTemp key={ sensor.key } sensor={ sensor } user={user} />
                                    )
                            })
                        }
                        </div>
                    </div>
                </div>
                <div className="w-100"></div>
            </div>
        )
    }
}