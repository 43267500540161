import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter, faCamera } from '@fortawesome/free-solid-svg-icons';//https://fontawesome.com/icons

/*Components of Automated-Life*/
import { useAuthValue } from "../Shared/AuthProvider";
import Notifications from "../Notifications/Notifications";
import Loading from "../Shared/Loading";
import { UpdateDevice, GetDevice } from "automated-provider";

export default function DeviceCamera(parameters) {
    const { currentUser } = useAuthValue();
    const [user, setUser] = useState(null);
    const [device, setDevice] = useState(null);
    const [mode, setMode] = useState("easy");
    const [loadDevice, setLoadDevice] = useState(true);

    useEffect(() => {
        setLoadDevice(true);
        setUser(parameters.user);
        setDevice(parameters.device);
        setLoadDevice(false);
    }, [])

    const changeMode = async () => {
        if (mode === "easy") {
            setMode("advance");
        } else {
            setMode("easy");
        }

    };

    const refresh = async (pid) => {
        if (pid !== undefined) {          
            GetDevice(currentUser.uid, pid).then((responseDevice) => {       
                setDevice(responseDevice);
            });
        }
    }

    const Notificacion = async (pid, type, status) => {
        //console.log("sid : " + sid + " - type : " + type + " - status :" + !status);
        if(type === "aviso_no"){
            device.aviso_no = true;
            device.aviso_email = false;
            device.aviso_sms = false;
            device.aviso_push = false;
        }else{
            device[type] = !status;
            device.aviso_no = false;
        }
        UpdateDevice(currentUser.uid, pid, device).then((response) => {
            refresh(pid);
        });
    };

    const ActivacionAutomatica = async (props) => {

    };

    if(loadDevice === true){
        return (<><div className={mode === "easy" ? 'col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4' : 'col-12'} ><Loading /></div></>);
    }else{
        return (
            <>
                <div className={mode === "easy" ? 'col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4' : 'col-12'} >
                    <div className="panel-device">
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-9 col-md-9 col-lg-9">
                                <FontAwesomeIcon className={parameters.device.estado === 'on' ? "status-enabled" : "status-disabled"} icon={faCamera} size="2x" />
                                &nbsp;&nbsp;
                                <span className="name">{parameters.device.nombre}</span>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 text-end">
                                <FontAwesomeIcon id={"icon_" + parameters.device.key} icon={mode === "easy" ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter} size="1x" onClick={() => changeMode()} />
                            </div>
                            {mode === "easy" ? (
                                <div className="col-12 text-center">
                                    <span className="description-device">{parameters.device.valor}</span>
                                </div>
                            ) : (
                                <>
                                    <div className="col-12 text-center">
                                    
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 center-on-small-only">
                                                <p className="text-xs-center">Notificación de activación y apagado</p>
                                                <div className="form-check form-switch form-check-inline">
                                                        <input type="checkbox" id={device.key + "_email"} checked={device.aviso_email === true ? true : false} name="notifications" onChange={() => Notificacion(device.key, 'aviso_email', device.aviso_email)} className="form-check-input" />
                                                        <label className="form-check-label">Email</label>
                                                    </div>
                                                    <div className="form-check form-switch form-check-inline">
                                                        <input type="checkbox" id={device.key + "_sms"} checked={device.aviso_sms === true ? true : false} name="notifications" onChange={() => Notificacion(device.key, 'aviso_sms', device.aviso_email)} className="form-check-input" />
                                                        <label className="form-check-label">SMS</label>
                                                    </div>
                                                    <div className="form-check form-switch form-check-inline">
                                                        <input type="checkbox" id={device.key + "_push"} checked={device.aviso_push === true ? true : false} name="notifications" onChange={() => Notificacion(device.key, 'aviso_push', device.aviso_email)} className="form-check-input" />
                                                        <label className="form-check-label">Push</label>
                                                    </div>
                                                    <div className="form-check form-switch form-check-inline">
                                                        <input type="checkbox" id={device.key + "_no"} checked={device.aviso_no === true ? true : false} name="notifications" onChange={() => Notificacion(device.key, 'aviso_no', device.aviso_email)} className="form-check-input" />
                                                        <label className="form-check-label">Desactivadas</label>
                                                    </div>
                                            </div>
                                            {parameters.device.manual === false ? (
                                                <>
                                                    <div className="w-100"></div>
                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-xs-center">
                                                        <p className="text-xs-center">Activación automática</p>
                                                        <div className="form-check form-switch ">
                                                            <input type="checkbox" id={parameters.device.key + "_activacion_automatica"} checked={parameters.device.activacion_automatica === true ? true : false} onChange={() => ActivacionAutomatica(parameters.device.key, false, 'pins')} className="form-check-input" />
                                                            <label className="form-check-label"></label>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (<>  </>)}                                        
                                            {parameters.device.activacion_automatica === true ? (
                                                <div className="col-12">
                                                    <p className="text-xs-center">Activaciones automáticas</p>
                                                    <div id={parameters.device.key + "_calendar"} data-key={parameters.device.key} ui-calendar="uiConfig.calendar" className="span8 calendar" ng-model="eventSources">
                                                    </div>
                                                </div>
                                            ) : (<>  </>)}
                                        </div>
                                    </div>
                                    <div className="w-100"></div>
                                    <Notifications deviceSensor={parameters.device} lastSize="Top-5" />
                                </>
                            )
                            }
                            <div className="col-12">
                                <p className={parameters.device.ultima_conexion_status === true ? 'status-enabled text-end' : 'status-disabled text-end'} data-toggle="popover" data-placement="top" data-content={parameters.device.ultima_conexion}>
                                    {parameters.device.ultima_conexion_status === true ? 'Con conexión' : 'Sin conexión'}
                                </p>
                            </div>
                            {mode === "advance" ? (
                                <>

                                </>
                            ) : (<>  </>)}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}