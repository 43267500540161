import { NotificationManager } from 'react-notifications';//https://www.npmjs.com/package/react-notifications
import {reactLocalStorage} from 'reactjs-localstorage';
import { Link, useNavigate } from 'react-router-dom';

/*Componentes of FireBase*/
import { getDatabase as getDataBaseFromFirebase, ref, onValue, set, push, update, remove, onChildAdded, onChildChanged, orderByChild } from "firebase/database";
import { initializeApp as FirebaseApp } from "firebase/app";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    updateCurrentUser,
    signOut,
    sendEmailVerification,
    onAuthStateChanged,
    setPersistence,
    browserSessionPersistence,
    browserLocalPersistence
} from "firebase/auth";

import firebaseConfig from "../firebaseConfig";

const auth = getAuth(FirebaseApp(firebaseConfig));

export const getDatabase = () => {
    return getDataBaseFromFirebase()
}

export const LoginUser = (email, password) => {
    return new Promise(async (resolve, reject) => {
        try {
            setPersistence(auth, browserLocalPersistence).then(() => {
                    // Existing and future Auth states are now persisted in the current
                    // session only. Closing the window would clear any existing state even
                    // if a user forgets to sign out.
                    // ...
                    // New sign-in will be persisted with session persistence.
                    signInWithEmailAndPassword(auth, email, password).then(response => { 
                        GetUserFireBase(response.user.uid).then((response_user) => {                            
                            UpdatePropertyUser(response.user.uid, 'fecha_ultima', new Date(new Date().toLocaleString('en', {timeZone: 'Europe/Madrid'})).toLocaleDateString()).then((response_updateUser) => {
                                resolve(response); 
                            });                            
                        })                        
                    }).catch((error) => {
                        // Handle Errors here.
                        console.log("Error LoginUser - Code : " + error.code + " - Message : " + error.message);
                        reject(error);
                    }); 
                }) 
        } catch (e) {
            reject(e);
        }
    });
};

export const LogOutUser = () => {
    return new Promise(async (resolve, reject) => {
        try {
            reactLocalStorage.clear();            
            signOut(auth).then(() => {
                resolve("SignOut OK");
            }).catch((error) => {
                reject("Error signOut");
            });
        } catch (e) {
            reject(e);
        }
    });
};

export const GetAuthenticatedUser = () => {
    return new Promise(async (resolve, reject) => {
        try {
            onAuthStateChanged(auth, (user) => {
                if (user !== null && user.emailVerified) {
                    GetConfig().then(async (response_config) => {
                        var loginFrom = ((Date.now() - new Date(parseFloat(user.metadata.lastLoginAt))) % (1000 * 60 * 60)) / (1000 * 60);
                        if(loginFrom > response_config.loginExpireMin){
                            LogOutUser().then(() => {
                                resolve(null);
                            }); 
                        }else{
                            resolve(user);
                        }
                    });
                                        
                }else{
                    resolve(null);
                }
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const ResetPassword = (email) => {
    return new Promise(async (resolve, reject) => {
        try {
            sendPasswordResetEmail(auth, email).then(response => { resolve(response); });
        } catch (e) {
            reject(e);
        }
    });
};

export const VerfificationEmail = (currentUser) => {
    return new Promise(async (resolve, reject) => {
        try {
            sendEmailVerification(currentUser).then(response => { resolve(response); });
        } catch (e) {
            reject(e);
        }
    });
};

export const TelegramPushNotification = (usuario_telegram, TextMessage) => {
    GetConfig().then(async (response_config) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ text: TextMessage, chat_id: usuario_telegram })
        };

        await fetch('https://api.telegram.org/' + response_config.telegram_bot_token + '/sendMessage', requestOptions).then(response => response.json())
        .then(data => {
            WebPushNotification("Notificación enviada correctamente", "Notificación al usuario", "success");
        });
    });
};

export const TelegramLasUserId = async () => {
    GetConfig().then(async (response_config) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        await fetch('https://api.telegram.org/' + response_config.telegram_bot_token + '/getupdates', requestOptions).then(response_fetch => {
            return response_fetch.json().then((response) => {
                return response;
            });
        });
    });
};

export const UpdateStatusDevice = async (ip, port, kid, StatusDevice) => {
    const requestOptions = {
        method: 'GET'
    };
    
    return await fetch((location.protocol == 'https:' ? 'https://' : 'http://') + ip + ':' + port + '/?key=' + kid + '&changeStatus=' + StatusDevice, requestOptions).then((response_fetch) => {
        return response_fetch.json().then((response) => {
            return response;
        });
    });
};

export const UpdateValueDevice = async (ip, port, kid, ValueDevice) => {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch((location.protocol == 'https:' ? 'https://' : 'http://') + ip + ':' + port + '/?key=' + kid + '&newValue=' + ValueDevice, requestOptions).then((response_fetch) => {
        return response_fetch.json().then((response) => {
            return response;
        });
    });
};

export const UpdateDevice = (uid, did, obj) => {
    return new Promise(async (resolve, reject) => {
        try {
            var dataUpdate = Object.assign({}, obj);

            delete dataUpdate['key'];
            delete dataUpdate['ultima_conexion_status'];

            set(ref(getDatabase(), 'cliente-pins/' + uid + '/' + did), dataUpdate).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const UpdatePropertyDevice = (uid, did, property, value) => {
    return new Promise(async (resolve, reject) => {
        try {
            set(ref(getDatabase(), 'cliente-pins/' + uid + '/' + did + '/' + property + '/'), value).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const SaveDevice = (uid, did, dataDevice) => {
    return new Promise(async (resolve, reject) => {
        try {
            var dataUpdate = Object.assign({}, dataDevice);

            delete dataUpdate['key'];
            delete dataUpdate['ultima_conexion_status'];

            set(ref(getDatabase(), 'cliente-pins/' + uid + '/' + did + '/'), dataUpdate).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const DeleteDevice = (uid, did) => {
    return new Promise(async (resolve, reject) => {
        try {
            set(ref(getDatabase(), 'cliente-pins/' + uid + '/' + did + '/'), null).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const SaveSensor = (uid, sid, dataSensor) => {
    return new Promise(async (resolve, reject) => {
        try {
            var dataUpdate = Object.assign({}, dataSensor);

            delete dataUpdate['key'];
            delete dataUpdate['ultima_conexion_status'];

            set(ref(getDatabase(), 'cliente-sensores/' + uid + '/' + sid + '/'), dataUpdate).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const DeleteSensor = (uid, sid) => {
    return new Promise(async (resolve, reject) => {
        try {
            set(ref(getDatabase(), 'cliente-sensores/' + uid + '/' + sid + '/'), null).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const UpdatePropertySensor = (uid, sid, property, status) => {
    return new Promise(async (resolve, reject) => {
        try {
            set(ref(getDatabase(), 'cliente-sensores/' + uid + '/' + sid + '/' + property + '/'), status).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const UpdateSensor = (uid, sid, obj) => {
    return new Promise(async (resolve, reject) => {
        try {
            var dataUpdate = Object.assign({}, obj);

            delete dataUpdate['key'];
            delete dataUpdate['ultima_conexion_status'];

            set(ref(getDatabase(), 'cliente-sensores/' + uid + '/' + sid), dataUpdate).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const UpdatePropertyUser = (uid, property, status) => {
    return new Promise(async (resolve, reject) => {
        try {
            set(ref(getDatabase(), 'clientes/' + uid + '/' + property + '/'), status).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const SaveUser = (uid, dataUser) => {
    return new Promise(async (resolve, reject) => {
        try {
            update(ref(getDatabase(), '/clientes/' + uid + '/'), dataUser).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const SaveNuevoUser = (uid, dataUser) => {
    return new Promise(async (resolve, reject) => {
        try {
            var dataUpdate = Object.assign({}, dataUser);
            delete dataUpdate['key'];          
            var currentUser = auth.currentUser;
            createUserWithEmailAndPassword(auth, dataUser.email, dataUser.contraseña).then(function (newUserResponse) {
                updateCurrentUser(auth, currentUser).then(function (updateUser) {
                    set(ref(getDatabase(), '/clientes/' + newUserResponse.user.uid), dataUpdate).then((response) => {
                        resolve(response);
                    });
                });
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const SaveUserAuthorize = (uid, auid, dataUser) => {
    return new Promise(async (resolve, reject) => {
        try {
            var dataUpdate = Object.assign({}, dataUser);
            delete dataUpdate['key'];
            update(ref(getDatabase(), '/clientes/' + uid + '/autorizados/' + auid), dataUpdate).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const DeleteUserAuthorize = (uid, auid) => {
    return new Promise(async (resolve, reject) => {
        try {
            set(ref(getDatabase(), 'clientes/' + uid + '/autorizados/' + auid + '/'), null).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const GetConfig = () => {
    return new Promise(async (resolve, reject) => {
        try {
            onValue(ref(getDatabase(), 'config/'), (data) => {
                resolve(data.val());
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const GetCurrentUserFireBase = (currentUser) => {
    return new Promise(async (resolve, reject) => {
        try {
            onValue(ref(getDatabase(), 'clientes/' + currentUser.uid + '/'), (data) => {
                let dataTemp = data.val();
                if (dataTemp !== null) {
                    dataTemp.key = currentUser.uid;
                    dataTemp.uid = currentUser.uid;
                    resolve(dataTemp);
                }
            });
        } catch (e) {
            reject(e);
        }
    });    
};

export const GetUserFireBase = (uid) => {
    return new Promise(async (resolve, reject) => {
        try {
            onValue(ref(getDatabase(), 'clientes/' + uid + '/'), (data) => {
                resolve(data.val());
            });
        } catch (e) {
            reject(e);
        }
    });
};

export const GetSensor = (uid, sid) => {
    return new Promise(async (resolve, reject) => {
        try {
            onValue(ref(getDatabase(), 'cliente-sensores/' + uid + '/' + sid + '/'), (data) => {
                let dataTemp = data.val();
                if (dataTemp !== null) {
                    dataTemp.key = data.key;
                    resolve(dataTemp);
                }
            });
        } catch (e) {
            reject(e);
        }
    });
};

export const ChangeSensor = (uid, sid) => {
    return new Promise(async (resolve, reject) => {
        try {
            onChildChanged(ref(getDatabase(), 'cliente-sensores/' + uid + '/' + sid + '/'), (data) => {
                let updateSensor = {};
                updateSensor.property = data.key;
                updateSensor.value = data.val();
                resolve(updateSensor);
            });
        } catch (e) {
            reject(e);
        }
    });
};

export const GetDevice = (uid, did) => {
    return new Promise(async (resolve, reject) => {
        try {
            onValue(ref(getDatabase(), 'cliente-pins/' + uid + '/' + did + '/'), (data) => {
                let dataTemp = data.val();
                if (dataTemp !== null) {
                    dataTemp.key = data.key;
                    resolve(dataTemp);
                }
            });
        } catch (e) {
            reject(e);
        }
    });
};

export const ChangeDevice = (uid, did) => {
    return new Promise(async (resolve, reject) => {
        try {
            onChildChanged(ref(getDatabase(), 'cliente-pins/' + uid + '/' + did + '/'), (data) => {
                let updateDevice = {};
                updateDevice.property = data.key;
                updateDevice.value = data.val();
                resolve(updateDevice);
            });
        } catch (e) {
            reject(e);
        }
    });
};

export const GetDevices = () => {
    return new Promise(async (resolve, reject) => {
        try {
            onChildAdded(ref(getDatabase(), 'cliente-pins/'), (data) => {
                let dataTemp = data.val();
                if (dataTemp !== null) {
                    var dataKeys = Object.keys(dataTemp);
                    dataKeys.forEach(function (item) {
                        dataTemp[item].key = item;
                        dataTemp[item].ultima_conexion_status = DiffInMinutesLastConnection(dataTemp[item].ultima_conexion);
                    });

                    resolve({ user : data.key, devices : dataTemp });
                }
            });
        } catch (e) {
            reject(e);
        }
    });
};

export const GetSensors = () => {
    return new Promise(async (resolve, reject) => {
        try {
            onChildAdded(ref(getDatabase(), 'cliente-sensores/'), (data) => {
                let dataTemp = data.val();
                if (dataTemp !== null) {
                    var dataKeys = Object.keys(dataTemp);
                    dataKeys.forEach(function (item) {
                        dataTemp[item].key = item;
                        dataTemp[item].ultima_conexion_status = DiffInMinutesLastConnection(dataTemp[item].ultima_conexion);
                    });
                    resolve({ user: data.key, sensors: dataTemp });
                }
            });
        } catch (e) {
            reject(e);
        }
    });
};

export const GetProgrammings = (uid, type, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            var list_programmings = [];

            onChildAdded(ref(getDatabase(), type + '/' + uid + '/' + id + '/activaciones_automaticas/'), (data) => {
                list_programmings.push({ key: data.key, type : type, config : data.val()});
            });  

            list_programmings = list_programmings.sort((a, b) => (a.config.fecha_inicio !== "--" && new Date(a.config.fecha_inicio)) >= (b.config.fecha_inicio !== "--" && new Date(b.config.fecha_inicio)) ? 1 : -1)
            list_programmings = list_programmings.sort((a, b) => (a.config.fecha_fin !== "--" && new Date(a.config.fecha_fin)) >= (b.config.fecha_fin !== "--" && new Date(b.config.fecha_fin)) ? 1 : -1)
            list_programmings = list_programmings.sort((a, b) => (a.config.fecha_inicio !== "--" && parseInt(a.config.hora_inicio.replace(":", ""))) >= (b.config.fecha_inicio !== "--" && parseInt(b.config.hora_inicio.replace(":", ""))) ? 1 : -1);

            setTimeout(function () {
                resolve(list_programmings);
            }, 1000);
        } catch (e) {
            reject(e);
        }
    });
};

export const DeleteProgrammings = (uid, type, id, pid) => {
    return new Promise(async (resolve, reject) => {
        try {
            remove(ref(getDatabase(), type + '/' + uid + '/' + id + '/activaciones_automaticas/'+pid));
            resolve();
        } catch (e) {
            reject(e);
        }
    });
};

export const SaveAutomaticProgramingDevice = (uid, did, dataAutomaticPrograming) => {
    return new Promise(async (resolve, reject) => {
        try {
            var dataUpdate = Object.assign({}, dataAutomaticPrograming);
            var key = dataUpdate['key'];
            delete dataUpdate['key'];
            set(ref(getDatabase(), dataAutomaticPrograming.type +'/' + uid + '/' + did + '/activaciones_automaticas/' + key), dataUpdate.config).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const GetNotificationsById = (uid, did, max) => {
    return new Promise(async (resolve, reject) => {
        try {
            var list_notifications = [];
            onChildAdded(ref(getDatabase(), 'cliente-notificaciones/' + uid, orderByChild("fecha")), (data) => {
                if (data.val().key === did) {
                    list_notifications.push({ key: data.val().key, fecha: data.val().fecha, descripcion: data.val().descripcion });
                }                
            });

            setTimeout(function () {
                list_notifications = list_notifications.sort((a, b) => new Date(a.fecha) >= new Date(b.fecha) ? 1 : -1);
                if (list_notifications !== undefined && list_notifications.length > 0 && max !== undefined && Number.isInteger(max)){
                    list_notifications = list_notifications.slice(0, max);
                }
                resolve(list_notifications);
            }, 1000);
        } catch (e) {
            reject(e);
        }
    });
};

export const GetTopNotifications = (uid, max) => {
    return new Promise(async (resolve, reject) => {
        try {
            var list_notifications = [];

            onChildAdded(ref(getDatabase(), 'cliente-notificaciones/' + uid), (data) => {
                list_notifications.push({ key: data.val().key, fecha: data.val().fecha, descripcion: data.val().descripcion });
            });

            setTimeout(function () {
                //list_notifications = list_notifications.sort((a, b) => new Date(a.fecha) >= new Date(b.fecha) ? 1 : -1);
                list_notifications = list_notifications.reverse();
                if (max !== undefined && Number.isInteger(max))
                    list_notifications = list_notifications.slice(0, max);

                resolve(list_notifications);
            },1000)           
        } catch (e) {
            reject(e);
        }
    });
};

export const SetNotification = (uid, data) => {
    var currentDate = new Date(new Date().toLocaleString('en', {timeZone: 'Europe/Madrid'}));

    return new Promise(async (resolve, reject) => {
        try {
            var dataUpdate = Object.assign({}, data);

            dataUpdate.fecha =  (currentDate.getDate() < 10 ? ("0" + currentDate.getDate()) : currentDate.getDate()) + '/' + ((currentDate.getMonth() +1) < 10 ? "0" + (currentDate.getMonth() + 1) : (currentDate.getMonth() +1)) + '/' + currentDate.getFullYear() + " " + (currentDate.getHours() < 10 ? "0" + currentDate.getHours() : currentDate.getHours()) + ':' + (currentDate.getMinutes() < 10 ? "0" + currentDate.getMinutes() : currentDate.getMinutes());

            push(ref(getDatabase(), 'cliente-notificaciones/' + uid), dataUpdate).then((response) => {
                resolve(response);
            });       
        } catch (e) {
            reject(e);
        }
    });
};

export const WebPushNotification = (title, description, type) => {
    return new Promise(async (resolve, reject) => {
        try {
            switch (type) {
                case 'info':
                    NotificationManager.info(title, description, 1500);
                    break;
                case 'success':
                    NotificationManager.success(title, description, 1500);
                    break;
                case 'warning':
                    NotificationManager.warning(title, description, 2500);
                    break;
                case 'error':
                    NotificationManager.error(title, description, 3500);
                    break;
                default : 
                    NotificationManager.info(title, description, 1500);
                    break;
            }
            resolve(true);
        } catch (e) {
            reject(e);
        }
    });
};

export const GetStatistics = (uid) => {
    var pinsSensores = [];

    return new Promise(async (resolve, reject) => {
        try
        {
            pinsSensores.push(new Promise(async (resolve, reject) => {
                var pins = [];
                onChildAdded(ref(getDatabase(), 'cliente-pins/' + uid), (ResponsePins) => {
                    var pin = {
                        name: ResponsePins.val().nombre,
                        data: [],
                        dataChart:[],
                        color: ResponsePins.val().color_grafica,
                        key: ResponsePins.key,                                        
                        orden_grafica: ResponsePins.val().orden_grafica,
                        entrada_salida : ResponsePins.val().entrada_salida,
                        type : ResponsePins.val().entrada_salida.tipo
                    };                
                    GetStatisticsFromPinOrSensorInternal(uid, 'pins', pin);
                    pins.push(pin);
                });  
                resolve(pins);
            }));  
            pinsSensores.push(new Promise(async (resolve, reject) => {
                var sensores = [];
                onChildAdded(ref(getDatabase(), 'cliente-sensores/' + uid), (ResponseSensores) => {
                    var sensor = {
                        name: ResponseSensores.val().nombre,
                        data: [],
                        dataChart:[],
                        color: ResponseSensores.val().color_grafica,
                        key: ResponseSensores.key,                                        
                        orden_grafica: ResponseSensores.val().orden_grafica,
                        type : ResponseSensores.val().tipo_sensor
                    };
                
                    GetStatisticsFromPinOrSensorInternal(uid, 'sensores', sensor);
                    sensores.push(sensor);                
                });
                resolve(sensores);
            }));

            setTimeout(function () {
                Promise.all(pinsSensores).then((response) => {
                    //console.log("pinSensores : " + JSON.stringify(response));
                    resolve(response.flat());
                });
            }, 1000);            
        } catch (e) {
            reject(e);
        }
    });
};

export const GetStatisticsFromDevice = (uid, pid, year, month, day) => {
    var pins = [];

    return new Promise(async (resolve, reject) => {
        try
        {
            pins.push(new Promise(async (resolve, reject) => {
                var pins = [];
                if(year != undefined && year != null && month != undefined && month != null && day != undefined && day != null){
                    onValue(ref(getDatabase(), 'cliente-pins/' + uid + '/' + pid +"/"), (ResponsePins) => {
                        var pin = {
                            name: ResponsePins.val().nombre,
                            data: [],
                            dataChart:[],
                            color: ResponsePins.val().color_grafica,
                            key: ResponsePins.key,                                        
                            orden_grafica: ResponsePins.val().orden_grafica,
                            entrada_salida : ResponsePins.val().entrada_salida,
                            type : ResponsePins.val().entrada_salida.tipo
                        };                
                        GetStatisticsFromPinOrSensorInternal(pid, 'pins', pin, year, month, day);
                        pins.push(pin);
                    });  
                }else{
                    onValue(ref(getDatabase(), 'cliente-pins/' + uid + '/' + pid+"/"), (ResponsePins) => {
                        var pin = {
                            name: ResponsePins.val().nombre,
                            data: [],
                            dataChart:[],
                            color: ResponsePins.val().color_grafica,
                            key: ResponsePins.key,                                        
                            orden_grafica: ResponsePins.val().orden_grafica,
                            entrada_salida : ResponsePins.val().entrada_salida,
                            type : ResponsePins.val().entrada_salida.tipo
                        };                
                        GetStatisticsFromPinOrSensorInternal(pid, 'pins', pin, null, null, null);
                        pins.push(pin);
                    });  
                }
                resolve(pins);
            }));            

            setTimeout(function () {
                Promise.all(pins).then((response) => {
                    resolve(response.flat());
                });
            }, 1000);            
        } catch (e) {
            reject(e);
        }
    });
};

export const GetStatisticsFromSensor = (uid, sid, year, month, day) => {
    var pinsSensores = [];

    return new Promise(async (resolve, reject) => {
        try
        {
            pinsSensores.push(new Promise(async (resolve, reject) => {
                var sensores = [];
                if(year != undefined && year != null && month != undefined && month != null && day != undefined && day != null){
                    onValue(ref(getDatabase(), 'cliente-sensores/' + uid + '/' + sid +"/"), (ResponseSensores) => {
                        var sensor = {
                            name: ResponseSensores.val().nombre,
                            data: [],
                            dataChart:[],
                            color: ResponseSensores.val().color_grafica,
                            key: ResponseSensores.key,                                        
                            orden_grafica: ResponseSensores.val().orden_grafica,
                            type : ResponseSensores.val().tipo_sensor
                        };
                        
                        GetStatisticsFromPinOrSensorInternal(uid, 'sensores', sensor, year, month, day);
                        sensores.push(sensor);                
                    }); 
                }else{
                    onValue(ref(getDatabase(), 'cliente-sensores/' + uid + '/' + sid+"/"), (ResponseSensores) => {
                        var sensor = {
                            name: ResponseSensores.val().nombre,
                            data: [],
                            dataChart:[],
                            color: ResponseSensores.val().color_grafica,
                            key: ResponseSensores.key,                                        
                            orden_grafica: ResponseSensores.val().orden_grafica,
                            type : ResponseSensores.val().tipo_sensor
                        };
                    
                        GetStatisticsFromPinOrSensorInternal(uid, 'sensores', sensor, null, null, null);
                        sensores.push(sensor);                
                    });
                }
                
                resolve(sensores);
            }));

            setTimeout(function () {
                Promise.all(pinsSensores).then((response) => {
                    resolve(response.flat());
                });
            }, 1000);            
        } catch (e) {
            reject(e);
        }
    });
};

export const SetStatisticsToDeviceOrSensor = (uid, dsid, type, status, value) => {
    var currentDate = new Date(new Date().toLocaleString('en', {timeZone: 'Europe/Madrid'}));
    return new Promise(async (resolve, reject) => {
        try {
            var dataSet = Object.assign({});
            dataSet['estado'] = status;
            dataSet['hora'] = (currentDate.getHours() < 10 ? "0" + currentDate.getHours() : currentDate.getHours()) + ':' + (currentDate.getMinutes() < 10 ? "0" + currentDate.getMinutes() : currentDate.getMinutes());
            dataSet['valor'] = parseFloat(value);

            push(ref(getDatabase(), 'cliente-' + type + '-historial' +'/' + uid + '/' + dsid + '/' + currentDate.getFullYear() + '/' + ((currentDate.getMonth() +1) < 10 ? "0" + (currentDate.getMonth() + 1) : (currentDate.getMonth() +1)) + '/' + (currentDate.getDate() < 10 ? ("0" + currentDate.getDate()) : currentDate.getDate())), dataSet).then((response) => {
                resolve(response);
            })
        } catch (e) {
            reject(e);
        }
    });
};

export const SetLocalStorageObject = (storage, data) => {    
    return new Promise(async (resolve, reject) => {
        try
        {
            reactLocalStorage.setObject(storage, data);
            resolve(data);
        } catch (e) {
            reject(e);
        }
    });    
};

export const GetLocalStorageObject = (storage) => {    
    return new Promise(async (resolve, reject) => {
        try
        {
            resolve(reactLocalStorage.getObject(storage));
        } catch (e) {
            reject(e);
        }
    });    
};

export const GetNewKeyOfArray = (array, prefix) => {
    var keys = [];
    array.forEach(function (obj) {
        keys.push(parseInt(obj.key.replace(prefix, '')));
    })

    var lastKey = keys.sort(function (a, b) { return a - b; });

    var newKey = 0;
    var max = 0;
    lastKey.forEach(function (item, index) {
        if (index !== undefined) {
            var maxTemp = (lastKey.length - 1 === index) ? lastKey[0] : lastKey[index + 1];
            if (maxTemp > max) {
                max = maxTemp;
            }
        }
        var range = Array.from({ length: max - item + 1 }, (_, i) => item + i);
        if (range.length > 0) {
            range.shift();
            range.pop();
            if (range.length > 0) {
                newKey = range[0];
            }
        }
    })
    if (newKey === 0) {
        newKey = max + 1;
    }
    return newKey;
};

export const customDatesFormatInput = (date) => {
    if(date !== "--"){
        var dateaux = new Date(date.split('/')[2],date.split('/')[1]-1,date.split('/')[0]);

        if(dateaux !== undefined){
            var yyyy = dateaux.getFullYear();
            var mm = dateaux.getMonth() + 1; // Months start at 0!
            var dd = dateaux.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            date = yyyy + '-' + mm + '-' + dd;  
            return date;
        }
    }
};

export const ChangeTemperature = (user, device, sensor, newTemp, modeWeb) => {
    return new Promise(async (resolve, reject) => {
        try {
            UpdatePropertyDevice(user.key, device.key, '/entrada_salida/valor', newTemp).then((response) => {
                UpdateValueDevice(user.ip, sensor.puerto != undefined ? sensor.puerto : user.puerto_ip, sensor.key, newTemp).then((response) => {
                    if(response.changeStatus)
                    {
                        if(modeWeb)
                            WebPushNotification(newTemp + "ºC", "Nueva temperatura en el dispositivo " + device.nombre, "success");
                    }else{
                        if(modeWeb)
                            WebPushNotification("El dispositivo "+ device.nombre+" no se ha actualizado correctamente", "Actualización de dispositivo", "error");
                    }                        
                });          
                /*UpdateValueDevice(user.ip, sensor.puerto != undefined ? sensor.puerto : user.puerto_ip, sensor.key, newTemp).then((response) => {
                    if(response.changeStatus)
                    {
                        if(modeWeb)
                            WebPushNotification(newTemp + "ºC", "Nueva temperatura en el dispositivo " + device.nombre, "success");
                                    
                        if (user && device.aviso_push) {
                            TelegramPushNotification(user.usuario_telegram, "Nueva temperatura en el dispositivo " + device.nombre);
                        }
                    }else{
                        if(modeWeb)
                            WebPushNotification("El dispositivo "+ device.nombre+" no se ha actualizado correctamente", "Actualización de dispositivo", "error");
                    }                        
                });*/                
            });            
            
            GetConfig().then((response_config) => {
                let diffTemp = parseFloat(newTemp) - parseFloat(sensor.valor)
                //console.log("temp validate (set) : " + parseFloat(newTemp) + " - sensor : " + parseFloat(sensor.valor) + " -> " + diffTemp);
                let actionDevice = "--"
                if (diffTemp >= 0) {
                    if (diffTemp >= parseFloat(response_config.diffTempOnOff))
                        actionDevice = "on";
                } else {
                    if (Math.abs(diffTemp) > Math.abs(parseFloat(response_config.diffTempOnOff)))
                    actionDevice = "off";
                }
                //console.log("actionDevice : " + actionDevice);

                if(actionDevice !== "--" && device.estado !== actionDevice){
                    UpdateStatusDevice(user.ip, device.puerto != undefined ? device.puerto : user.puerto_ip, device.key, actionDevice).then((response) => {
                        if(response.changeStatus)
                        {
                            if(modeWeb)
                                WebPushNotification(device.nombre, (actionDevice === "off" ? "Desactivado/a" : "Activado/a"), "success");
                        }else{
                            if(modeWeb)
                                WebPushNotification("El dispositivo "+ device.nombre+" no se ha actualizado correctamente", "Actualización de dispositivo", "error");
                        }                   
                    });
                    /*UpdatePropertyDevice(user.key, device.key, '/estado', actionDevice).then((response) => {
                        UpdateStatusDevice(user.ip, device.puerto != undefined ? device.puerto : user.puerto_ip, device.key, actionDevice).then((response) => {
                            if(response.changeStatus)
                            {
                                if(modeWeb)
                                    WebPushNotification(device.nombre, (actionDevice === "off" ? "Desactivado/a" : "Activado/a"), "success");
        
                                if (user && sensor.aviso_push) {
                                    TelegramPushNotification(user.usuario_telegram, device.nombre + (actionDevice === "off" ? " desactivado/a" : " activado/a"));
                                }
                            }else{
                                if(modeWeb)
                                    WebPushNotification("El dispositivo "+ device.nombre+" no se ha actualizado correctamente", "Actualización de dispositivo", "error");
                            }                   
                        }); 
                    });*/
                }
            });                               
            
            resolve(true);            
        } catch (e) {
            reject(e);
        }
    });    
};

function DiffInMinutesLastConnection (date) {
    if(date == undefined || date == null){
        return false;
    }
    
    var dateArray = date.split(' ');

    var currentTime = new Date(new Date().toLocaleString('en', {timeZone: 'Europe/Madrid'}));    
    var dateParse = new Date (new Date(dateArray[0].split("-")[2], (parseInt(dateArray[0].split("-")[1])-1), dateArray[0].split("-")[0], dateArray[1].split(":")[0], dateArray[1].split(":")[1], dateArray[1].split(":")[2]).toLocaleString('en', {timeZone: 'Europe/Madrid'}));    
    var diff = Math.floor((currentTime-dateParse) / (1000 * 60 * 60));

    if(diff <= 5 && diff >= 0)
        return true;
    else
        return false;
};

function GetStatisticsFromPinOrSensorInternal(uid, type, pinSensor, year, month, day){
    if(month != null && day != null){
        month = parseInt((month +1) < 10 ? "0" + (month + 1) : (month +1));
        day = parseInt((day < 10 ? ("0" + day) : day));
    }

    onChildAdded(ref(getDatabase(), 'cliente-'+type+'-historial/' + uid), (ResponseData) => {
        //console.log("Data : " + 'cliente-'+type+'-historial/' + uid + '/' + ResponseData.key);
        onChildAdded(ref(getDatabase(), 'cliente-'+type+'-historial/' + uid + '/' + ResponseData.key), (ResponseStatistic) => {
            //PINs            
            //console.log("type : " + pinSensor.type);

            switch(pinSensor.type){
                case "temperatura_in":
                case "temperatura":
                    //console.log("key : " + ResponseData.key);
                    
                    if (pinSensor.key === ResponseData.key) {                        

                        //console.log("year : " + "-" + year + " - month : " + month + " - day : " + day);
                        //console.log("node : " + 'cliente-'+type+'-historial/' + uid + '/' + ResponseData.key + "/" + ResponseStatistic.key);
                        //PIN
                        if(year == null && month == null && day == null)
                        {
                            onChildAdded(ref(getDatabase(), 'cliente-'+type+'-historial/' + uid + '/' + ResponseData.key + "/" + ResponseStatistic.key), (ResponseData_anio) => {
                                var data_anio = ResponseData_anio.val();
                                //AÑO
                                onChildAdded(ref(getDatabase(), 'cliente-'+type+'-historial/' + uid + '/' + ResponseData.key + "/" + ResponseStatistic.key+ "/" + ResponseData_anio.key), (ResponseData_mes) => {
                                    var data_mes = ResponseData_mes.val();
                                    //MES
                                    onChildAdded(ref(getDatabase(), 'cliente-'+type+'-historial/' + uid + '/' + ResponseData.key + "/" + ResponseStatistic.key+ "/" + ResponseData_anio.key+ "/" + ResponseData_mes.key), (ResponseData_dia) => {
                                        var data_dia = ResponseData_dia.val();
                                        //DIA
                                        var estado, hora, valor;
                                        onChildAdded(ref(getDatabase(), 'cliente-'+type+'-historial/' + uid + '/' + ResponseData.key + "/" + ResponseStatistic.key+ "/" + ResponseData_anio.key+ "/" + ResponseData_mes.key + "/" + ResponseData_dia.key), (ResponseData_item) => {
                                            if (ResponseData_item.key == "estado") {
                                                estado = ResponseData_item.val();
                                            }
                                            if (ResponseData_item.key == "hora") {
                                                hora = ResponseData_item.val();
                                            }
                                            if (ResponseData_item.key == "valor") {
                                                valor = ResponseData_item.val();
                                            }  
                                        });

                                        //console.log("Item : " + parseInt(ResponseStatistic.key) + "/" + (parseInt(ResponseData_anio.key) < 10 ? ("0" + parseInt(ResponseData_anio.key)) : parseInt(ResponseData_anio.key)) + "/" + (parseInt(ResponseData_mes.key) < 10 ? ("0" + parseInt(ResponseData_mes.key)) : parseInt(ResponseData_mes.key)) + " " + hora + ":00");
                                        pinSensor.data.push({ fecha: parseInt(ResponseStatistic.key) + "/" + (parseInt(ResponseData_anio.key) < 10 ? ("0" + parseInt(ResponseData_anio.key)) : parseInt(ResponseData_anio.key)) + "/" + (parseInt(ResponseData_mes.key) < 10 ? ("0" + parseInt(ResponseData_mes.key)) : parseInt(ResponseData_mes.key)) + " " + hora, estado: estado, valor: valor});
                                    });
                                });
                            });
                        }else{
                            var estado, hora, valor;
                            onChildAdded(ref(getDatabase(), 'cliente-'+type+'-historial/' + uid + '/' + ResponseData.key +'/' + year + "/" + month + "/" + day), (ResponseData_item) => {
                                estado = ResponseData_item.val().estado;
                                valor = ResponseData_item.val().valor;
                                hora = ResponseData_item.val().hora;        
                                //console.log("hora : " + hora);
                            });
                            pinSensor.data.push({ fecha: parseInt(year) + "/" + (parseInt(month) < 10 ? ("0" + parseInt(month)) : parseInt(month)) + "/" + (parseInt(day) < 10 ? ("0" + parseInt(day)) : parseInt(day)) + " " + hora, estado: estado, valor: valor});                           
                        }
                    }
                    break;
                case "relay":
                  
                    break;
                case "nivel":

                    break;
                default:
                    break;
            }                        
        });                    
    });
}